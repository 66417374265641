import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../core/QueryResponseProvider'
import {useQueryRequest} from 'app/modules/utilities/QueryRequestsProvider'
import usePaginationResume from 'app/helpers/usePaginationResume'

const ObligationClaimPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const pagesToRender = usePaginationResume({
    links: pagination.links,
    currentPage: pagination.page,
    max: 8,
  })

  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({page, items_per_page: pagination.items_per_page || 10})
  }

  return (
    <div className='row mt-12'>
      <div className='d-flex align-items-center justify-content-center'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {pagesToRender?.map((link) => (
              <li
                key={link.label}
                className={clsx('page-item', {
                  active: pagination.page === link.page,
                  disabled: isLoading,
                  previous: link.label === '&laquo; Previous',
                  next: link.label === 'Next &raquo;',
                })}
              >
                <a
                  className='page-link btn btn-sm btn-bg-secondary'
                  onClick={() => updatePage(link.page)}
                  style={{cursor: 'pointer'}}
                >
                  {link.label == '&laquo; Previous'
                    ? '<'
                    : link.label == 'Next &raquo;'
                    ? '>'
                    : link.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ObligationClaimPagination}
