import {useField} from 'formik'

const TextArea = ({...props}) => {
  //@ts-ignore
  const [field] = useField(props)

  return <textarea className='form-control' {...field} {...props} />
}

export default TextArea
