import useTranslate from 'app/hooks/useTranslate'
import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import {initialQueryState} from '_metronic/helpers'
import {useQueryRequest} from 'app/modules/utilities/QueryRequestsProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {ObligationClaim} from '../../core/_models'

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<ObligationClaim>>
  sort?: boolean
}
const CustomHeader: FC<Props> = ({className, title, tableProps, sort = false}) => {
  const __ = useTranslate()

  const id = tableProps.column.id
  const {state, updateState} = useQueryRequest()
  const {refetch} = useQueryResponse()

  const isSelectedForSorting = useMemo(() => {
    return state.sort && state.sort === id
  }, [state, id])
  const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

  const sortColumn = () => {
    // avoid sorting for these columns
    if (!sort) {
      return
    }

    if (!isSelectedForSorting) {
      // enable sort asc
      updateState({sort: id, order: 'asc', ...initialQueryState})

      //@ts-ignore
      refetch({
        sorts: [id],
      })
      return
    }

    if (isSelectedForSorting && order !== undefined) {
      if (order === 'asc') {
        // enable sort desc
        updateState({sort: id, order: 'desc', ...initialQueryState})

        //@ts-ignore
        refetch({
          sorts: ['-' + id],
        })
        return
      }

      // disable sort
      updateState({sort: undefined, order: undefined, ...initialQueryState})
    }
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{cursor: 'pointer'}}
      onClick={sortColumn}
    >
      {title && __(title, title)}
    </th>
  )
}

export {CustomHeader}
