import {FC} from 'react'

import useTranslate from 'app/hooks/useTranslate'
import {KTSVG} from '_metronic/helpers'

type Props = {
  toggleModal: any
}

const Toolbar: FC<Props> = ({toggleModal}) => {
  const __ = useTranslate()

  return (
    <div className='d-flex flex-wrap my-1' data-kt-user-table-toolbar='base'>
      <button type='button' className='btn btn-primary btn-sm' onClick={() => toggleModal(true)}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        {__('addClaimTitle', 'Nuevo Reclamo')}
      </button>
    </div>
  )
}

export {Toolbar}
