import {FC, useMemo, useEffect} from 'react'
import {Field, useFormikContext} from 'formik'
import {ErrorMessageField} from 'app/modules/utilities/alerts'

import useTranslate from 'app/hooks/useTranslate'
import {useGetCountries, useGetStates, useGetCities} from 'app/services/SettingService'
import {usePreviousValue} from 'app/helpers/usePreviousValue'
import {SingleSelect} from 'app/modules/widgets/components/inputs'

type Props = {
  k: number
}

const ThirdPartyAddresses: FC<Props> = ({k}) => {
  const __ = useTranslate()

  const {values, setFieldValue}: any = useFormikContext()

  const {data: dataCountries, loading: loadingCountries} = useGetCountries()
  const countries = useMemo(() => dataCountries, [dataCountries])

  const {
    data: states,
    refresh: refreshStates,
    loading: loadingStates,
  } = useGetStates({country_id: values.thirdParty.addresses[k]?.country?.value})
  const {
    data: cities,
    refresh: refreshCities,
    loading: loadingCities,
  } = useGetCities({
    country_id: values.thirdParty.addresses[k]?.country?.value,
    state_id: values.thirdParty.addresses[k]?.state?.value,
  })

  const previousValueCountry = usePreviousValue(values.thirdParty.addresses[k]?.country?.value)
  const previousValueState = usePreviousValue(values.thirdParty.addresses[k]?.state?.value)

  useEffect(() => {
    if (
      previousValueCountry ||
      values.thirdParty.addresses[k]?.country?.value !== previousValueCountry
    ) {
      refreshStates()
    }

    if (!values.thirdParty.addresses[k]?.id) return
  }, [values.thirdParty.addresses[k]?.country])

  useEffect(() => {
    if (previousValueState || values.thirdParty.addresses[k]?.state?.value !== previousValueState) {
      refreshCities()
    }

    if (!values.thirdParty.addresses[k]?.id) return
  }, [values.thirdParty.addresses[k]?.state])

  return (
    <div key={k} className='border'>
      <div className='d-flex justify-content-center mb-6 mt-6'>
        <h2 className='fw-bold fs-6'>{__('addressTitle', 'Dirección')}</h2>
      </div>

      <div className='row mb-6 '>
        <div className='col-md-3'>
          <label className='fs-6 fw-bold form-label mb-2'>{__('countryTitle', 'Pais')}</label>

          <SingleSelect
            placeholder={
              loadingCountries
                ? __('loadingTitle', 'Cargando') + '...'
                : __('selectTitle', 'Seleccionar')
            }
            isLoading={loadingCountries}
            isDisabled={loadingCountries}
            options={countries?.map((i: {attributes: {name: string}; id: any}) => ({
              label: i.attributes.name,
              value: i.id,
            }))}
            onChange={setFieldValue}
            className='basic-single'
            name={'thirdParty.addresses[' + k + '].country'}
            defaultValue={values.thirdParty.addresses[k]?.country}
            value={values.thirdParty.addresses[k]?.country}
          />
        </div>

        <div className='col-md-3'>
          <label className='fs-6 fw-bold form-label mb-2'>{__('stateTitle', 'Departamento')}</label>

          <SingleSelect
            placeholder={
              loadingStates
                ? __('loadingTitle', 'Cargando') + '...'
                : __('selectTitle', 'Seleccionar')
            }
            isLoading={loadingStates}
            isDisabled={loadingStates}
            options={states?.map((i: {attributes: {name: string}; id: any}) => ({
              label: i.attributes.name,
              value: i.id,
            }))}
            onChange={setFieldValue}
            className='basic-single'
            name={'thirdParty.addresses[' + k + '].state'}
            defaultValue={values.thirdParty.addresses[k]?.state}
            value={values.thirdParty.addresses[k]?.state}
          />
        </div>

        <div className='col-md-3'>
          <label className='required fs-6 fw-bold form-label mb-2'>
            {__('cityTitle', 'Ciudad')}
          </label>

          <SingleSelect
            placeholder={
              loadingCities
                ? __('loadingTitle', 'Cargando') + '...'
                : __('selectTitle', 'Seleccionar')
            }
            isLoading={loadingCities}
            isDisabled={loadingCities}
            options={cities?.map((i: {attributes: {name: string}; id: any}) => ({
              label: i.attributes.name,
              value: i.id,
            }))}
            onChange={setFieldValue}
            className='basic-single'
            name={'thirdParty.addresses[' + k + '].city_id'}
            defaultValue={values.thirdParty.addresses[k]?.city_id}
            value={values.thirdParty.addresses[k]?.city_id}
          />

          <ErrorMessageField field={'thirdParty.addresses[' + k + '].city_id'} />
        </div>

        <div className='col-md-3 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>
            {__('addressTitle', 'Dirección')}
          </label>
          <Field
            type='text'
            className='form-control'
            name={'thirdParty.addresses[' + k + '].address'}
            value={values.thirdParty.addresses[k]?.address}
          />

          <ErrorMessageField field={'thirdParty.addresses[' + k + '].address'} />
        </div>
      </div>

      <div className='row md-12 mt-10 mb-10'>
        <div className='col-md-12'>
          <label className='form-label'>
            {__('additionalInformationTitle', 'Complemento dirección')}
          </label>
          <Field
            as='textarea'
            placeholder={__('additionalInformationTitle', 'Complemento dirección')}
            name={'thirdParty.addresses[' + k + '].additional'}
            className='form-control'
            rows={2}
          ></Field>
        </div>
      </div>
    </div>
  )
}

export default ThirdPartyAddresses
