import React from 'react'
import ApiClientConfigurator from '@alejandro.devop/react-json-api-client'
import endpoints from '../../config/endpoints.json'
import {useSession} from '@alejandro.devop/redux-persistance'

interface Props {
  children: React.ReactNode
}

const AppWrapper: React.FC<Props> = ({children}) => {
  const {clear, store} = useSession()

  const handleRequestFailed = React.useCallback(
    (data) => {
      if (data?.errors) {
        const [error] = data?.errors
        if (error && error?.status === '401' && error?.title === 'Unauthenticated') {
          // Due to at this point there is no navigation context the redirection should be done
          // by location api.
          window.location.replace('/')

          clear({})
        }
      }
    },
    [clear]
  )

  return (
    <ApiClientConfigurator
      config={{
        server: process.env.REACT_APP_API_URL || 'notFound',
        endpoints,
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
        auth: `Bearer ${store?.token}`,
        payloadTransform: (payload: {[key: string]: any}) => {
          payload.device_name = 'web'
          return payload
        },
        //@ts-ignore
        onRequestFail: handleRequestFailed,
      }}
    >
      <>{children}</>
    </ApiClientConfigurator>
  )
}

export default AppWrapper
