/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout, usePageData} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import useTranslate from 'app/hooks/useTranslate'

export function HeaderWrapper() {
  const __ = useTranslate()

  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  const {pageTitle, pageDescription, pageAction} = usePageData()

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/banner' className='d-lg-none'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-30px' />
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/' className='d-lg-none'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-30px' />
            </Link>
          </div>
        )}

        <div
          className='d-flex align-items-center d-none d-lg-block d-print-block'
          id='kt_docs_header_title'
        >
          <div
            data-kt-swapper='true'
            data-kt-swapper-mode='prepend'
            data-kt-swapper-parent="{default: '#kt_docs_content_container', 'lg': '#kt_docs_header_title'}"
            className='docs-page-title d-flex flex-column flex-lg-row align-items-lg-center py-5 mb-lg-0'
          >
            {pageTitle && (
              <>
                <h1 className='d-flex align-items-center text-gray-900 my-1 fs-4'>
                  {pageTitle}
                  <span className='badge badge-success bg-hover-danger text-hover-white fw-bold fs-9 px-2 ms-2'>
                    {__('moduleTitle', 'Módulo')}
                  </span>
                </h1>
                <span className='d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4'></span>
              </>
            )}

            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-6 my-1'>
              {pageTitle && (
                <>
                  <li className='breadcrumb-item text-gray-60'>
                    <span className='text-gray-600'>Base</span>
                  </li>
                </>
              )}

              {pageDescription && (
                <>
                  <li className='breadcrumb-item text-gray-60'>
                    <span className='bullet bg-gray-200 w-5px h-2px'></span>
                  </li>
                  <li className='breadcrumb-item text-gray-900'>{pageDescription}</li>
                </>
              )}
            </ul>

            {pageAction && (
              <>
                <span className='d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4'></span>
                <span className='text-gray-600'>{pageAction}</span>
              </>
            )}
          </div>
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
