import {FC, useEffect, useMemo} from 'react'
import {Field, useFormikContext} from 'formik'

import useTranslate from 'app/hooks/useTranslate'

import {
  useGetCountries,
  useGetStates,
  useGetCities,
  useGetTelephoneTypes,
} from 'app/services/SettingService'
import {ErrorMessageField} from 'app/modules/utilities/alerts'
import {usePreviousValue} from 'app/helpers/usePreviousValue'
import {SingleSelect} from 'app/modules/widgets/components/inputs'

type addressProps = {
  k: number
}

const ThirdPartyTelephones: FC<addressProps> = ({k}) => {
  const __ = useTranslate()

  const {values, setFieldValue}: any = useFormikContext()

  const {data: telephoneTypes, loading: loadingTelephoneTypes} = useGetTelephoneTypes()

  const {data: dataCountries, loading: loadingCountries} = useGetCountries()
  const countries = useMemo(() => dataCountries, [dataCountries])

  const {
    data: states,
    refresh: refreshStates,
    loading: loadingStates,
  } = useGetStates({country_id: values.thirdParty.telephones[k]?.country?.value})

  const {
    data: cities,
    refresh: refreshCities,
    loading: loadingCities,
  } = useGetCities({
    country_id: values.thirdParty.telephones[k]?.country?.value,
    state_id: values.thirdParty.telephones[k]?.state?.value,
  })

  const previousValueCountry = usePreviousValue(values.thirdParty.telephones[k]?.country?.value)
  const previousValueState = usePreviousValue(values.thirdParty.telephones[k]?.state?.value)

  useEffect(() => {
    if (
      previousValueCountry ||
      values.thirdParty.telephones[k]?.country?.value !== previousValueCountry
    ) {
      refreshStates()
    }

    if (!values.thirdParty.telephones[k]?.id) return
  }, [values.thirdParty.telephones[k]?.country])

  useEffect(() => {
    if (
      previousValueState ||
      values.thirdParty.telephones[k]?.state?.value !== previousValueState
    ) {
      refreshCities()
    }

    if (!values.thirdParty.telephones[k]?.id) return
  }, [values.thirdParty.telephones[k]?.state])

  return (
    <div key={k} className='border'>
      <div className='d-flex justify-content-center mb-6 mt-6'>
        <h2 className='fw-bold fs-6'>{__('telephoneTitle', 'Telefono')}</h2>
      </div>

      <div className='row mb-10'>
        <div className='col-md-3'>
          <label className='fs-6 fw-bold form-label mb-2'>
            {__('telephoneTypeTitle', 'Tipo de Telefono')}
          </label>

          <SingleSelect
            placeholder={
              loadingTelephoneTypes
                ? __('loadingTitle', 'Cargando') + '...'
                : __('selectTitle', 'Seleccionar')
            }
            isLoading={loadingTelephoneTypes}
            isDisabled={loadingTelephoneTypes}
            options={telephoneTypes?.map(
              (i: {attributes: {name: string; need_city: boolean}; id: any}) => ({
                label: i.attributes.name,
                value: i.id,
                need_city: i.attributes.need_city,
              })
            )}
            onChange={setFieldValue}
            className='basic-single'
            name={'thirdParty.telephones[' + k + '].telephone_type_id'}
            value={values.thirdParty.telephones[k]?.telephone_type_id}
          />

          <ErrorMessageField field={'thirdParty.telephones[' + k + '].telephone_type_id'} />
        </div>

        {values.thirdParty.telephones[k]?.telephone_type_id?.need_city && (
          <>
            <div className='col-md-3'>
              <label className='fs-6 fw-bold form-label mb-2'>{__('countryTitle', 'Pais')}</label>

              <SingleSelect
                placeholder={
                  loadingCountries
                    ? __('loadingTitle', 'Cargando') + '...'
                    : __('selectTitle', 'Seleccionar')
                }
                isLoading={loadingCountries}
                isDisabled={loadingCountries}
                value={values.thirdParty.telephones[k]?.country}
                options={countries?.map((i: {attributes: {name: string}; id: any}) => ({
                  label: i.attributes.name,
                  value: i.id,
                }))}
                onChange={setFieldValue}
                className='basic-single'
                name={'thirdParty.telephones[' + k + '].country'}
              />
            </div>

            <div className='col-md-3'>
              <label className='fs-6 fw-bold form-label mb-2'>
                {__('stateTitle', 'Departamento')}
              </label>

              <SingleSelect
                placeholder={
                  loadingStates
                    ? __('loadingTitle', 'Cargando') + '...'
                    : __('selectTitle', 'Seleccionar')
                }
                isLoading={loadingStates}
                isDisabled={loadingStates}
                value={values.thirdParty.telephones[k]?.state}
                options={states?.map((i: {attributes: {name: string}; id: any}) => ({
                  label: i.attributes.name,
                  value: i.id,
                }))}
                onChange={setFieldValue}
                className='basic-single'
                name={'thirdParty.telephones[' + k + '].state'}
              />
            </div>

            <div className='col-md-3'>
              <label className='required fs-6 fw-bold form-label mb-2'>
                {__('cityTitle', 'Ciudad')}
              </label>

              <SingleSelect
                placeholder={
                  loadingCities
                    ? __('loadingTitle', 'Cargando') + '...'
                    : __('selectTitle', 'Seleccionar')
                }
                isLoading={loadingCities}
                isDisabled={loadingCities}
                value={values.thirdParty.telephones[k]?.city_id}
                options={cities?.map((i: {attributes: {name: string}; id: any}) => ({
                  label: i.attributes.name,
                  value: i.id,
                }))}
                onChange={setFieldValue}
                className='basic-single'
                name={'thirdParty.telephones[' + k + '].city_id'}
              />

              <ErrorMessageField field={'thirdParty.telephones[' + k + '].city_id'} />
            </div>
          </>
        )}

        <div className='col-md-3'>
          <label className='fs-6 fw-bold form-label mb-2'>
            {__('phoneNumberTitle', 'Número de Telefono')}
          </label>
          <Field
            type='text'
            className='form-control'
            name={'thirdParty.telephones[' + k + '].number'}
            value={values.thirdParty.telephones[k]?.number}
            maxLength={`${
              values.thirdParty.telephones[k]?.telephone_type_id?.need_city ? '7' : '10'
            }`}
          />

          <ErrorMessageField field={'thirdParty.telephones[' + k + '].number'} />
        </div>
      </div>
    </div>
  )
}

export default ThirdPartyTelephones
