import {useField, useFormikContext} from 'formik'
import 'react-datepicker/dist/react-datepicker.css'
//@ts-ignore
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker'
import {es} from 'date-fns/locale'
registerLocale('es', es)

const DatePickerField = ({...props}) => {
  const {setFieldValue} = useFormikContext()
  //@ts-ignore
  const [field] = useField(props)

  return (
    <DatePicker
      {...field}
      {...props}
      locale='es'
      selected={(field.value && new Date(field.value)) || null}
      //@ts-ignore
      onChange={(val) => {
        setFieldValue(field.name, val)
      }}
      dateFormat='yyyy-MM-dd'
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode='select'
    />
  )
}

export default DatePickerField
