import useTranslate from 'app/hooks/useTranslate'
import {FC} from 'react'

const Error404: FC = () => {
  const __ = useTranslate()

  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
        {__('404Title', 'Página no encontrada')}
      </h1>

      <div className='fw-bold fs-3 text-gray-700 mb-15'>
        {__('404Description', '!La páginal que buscas no fue Encontrada!')} <br />
      </div>
    </>
  )
}

export {Error404}
