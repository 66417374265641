import React, {useEffect, MouseEventHandler, useState, useRef} from 'react'
import * as Yup from 'yup'
import _ from 'lodash'

import {useFormik, FormikHelpers} from 'formik'
import {useSession} from '@alejandro.devop/redux-persistance'
import {useNavigate, Link} from 'react-router-dom'
import {UsersListToolbar} from '../../users/list/header/UserListToolbar'
import useTranslate from 'app/hooks/useTranslate'

interface TwoStepFormProps {
  loading: boolean
  onSubmit: (values: any, options: FormikHelpers<any>) => Promise<void>
  onLogout: MouseEventHandler
}

const validationSchema = Yup.object().shape({
  twoStepInput0: Yup.string()
    .min(1, 'Minimum 1 characteres')
    .max(1, 'Maximum 1 characteres')
    .required('is required'),
  twoStepInput1: Yup.string()
    .min(1, 'Minimum 1 characteres')
    .max(1, 'Maximum 1 characteres')
    .required('is required'),
  twoStepInput2: Yup.string()
    .min(1, 'Minimum 1 characteres')
    .max(1, 'Maximum 1 characteres')
    .required('is required'),
  twoStepInput3: Yup.string()
    .min(1, 'Minimum 1 characteres')
    .max(1, 'Maximum 1 characteres')
    .required('is required'),
  twoStepInput4: Yup.string()
    .min(1, 'Minimum 1 characteres')
    .max(1, 'Maximum 1 characteres')
    .required('is required'),
  twoStepInput5: Yup.string()
    .min(1, 'Minimum 1 characteres')
    .max(1, 'Maximum 1 characteres')
    .required('is required'),
})

const initialValues = {
  twoStepInput0: '',
  twoStepInput1: '',
  twoStepInput2: '',
  twoStepInput3: '',
  twoStepInput4: '',
  twoStepInput5: '',
  registerSafeComputer: false,
}

const TwoStepForm: React.FC<TwoStepFormProps> = ({loading, onSubmit, onLogout}) => {
  const {setAll, store = {}} = useSession()
  const __ = useTranslate()

  const navigate = useNavigate()
  const [currentInput, setCurrentInput] = useState(0)
  const inputRefs: any = useRef([])
  const [value, setValue] = useState(' ')

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  const handleClick = ({target}: {target: any}, key: any) => {
    target.select()
    setCurrentInput(key)
  }

  const handleKeyUp = (event: any, index: any) => {
    const enteredKey = event.key
    let nextValue = enteredKey
    let nextInput: any = inputRefs.current[index + 1]
    if (['Tab', 'Alt'].includes(enteredKey)) {
      return false
    }
    if (enteredKey === 'Backspace') {
      nextInput = inputRefs.current[index - 1]
      nextValue = ''
    }
    const outputValue = value.substring(0, index) + nextValue + value.substring(index + 1)

    if (nextInput) {
      nextInput.focus()
    }

    setValue(outputValue)
  }

  useEffect(() => {
    if (!store?.isTowFactor && !store.token) {
      setAll({})
      navigate('/auth')
    }
  }, [])

  return (
    <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
      <form
        className='form w-100 py-5 py-lg-5'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_sing_in_two_steps_form'
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>{__('twoStepTitle', 'Verificación a dos Pasos')}</h1>

          <div className='alert alert-secondary d-flex align-items-center p-5 mb-10'>
            <div className='d-flex flex-column'>
              <span
                /* @ts-ignore */
                style={{textAlign: 'justify', textJustify: 'inter-word'}}
              >
                {__(
                  'twoStepDescription',
                  'Su Seguridad es muy importante para PROCRÉDITO por este motivo, hemos enviado un código de seguridad a su correo electrónico para que pueda acceder a la plataforma, recuerda que el código expira en 10 minutos, para recibir otro código debes iniciar sesión.'
                )}
              </span>
            </div>
          </div>

          <div className='fw-bold fs-5 mb-5'>
            {__('twoStepEnterCodeTitle', 'Ingresa el código de seguridad enviado al correo:')}
          </div>

          <div className='fw-bolder text-dark fs-3'>{store?.user?.user_email}</div>
        </div>

        <div className='mb-10'>
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold text-align-center'>{formik.status}</div>
            </div>
          ) : (
            ''
          )}

          <div className='d-flex flex-wrap flex-stack'>
            {_.times(6, (key) => (
              <input
                type='text'
                maxLength={1}
                autoComplete='off'
                key={`input-${key}`}
                ref={(ref) => (inputRefs.current[key] = ref)}
                onClick={(e) => handleClick(e, key)}
                onFocus={(e) => handleClick(e, key)}
                onKeyUp={(e) => handleKeyUp(e, key)}
                autoFocus={currentInput === key}
                className='form-control h-60px w-60px fs-2qx text-center mx-1 my-2'
                style={{border: '1px solid rgba(0, 0, 0, 0.25)'}}
                {...formik.getFieldProps(`twoStepInput${key}`)}
              />
            ))}
          </div>
        </div>

        <div className='fv-row mb-10'>
          <div className='form-check form-check-custom form-check-success form-check-lg'>
            <input
              className='form-check-input'
              style={{border: '2px solid rgba(0, 0, 0, 0.25)'}}
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('registerSafeComputer')}
            />
            <label
              className='form-check-label fw-bold text-gray-700 fs-6'
              /* @ts-ignore */
              style={{textAlign: 'justify', textJustify: 'inter-word'}}
              htmlFor='kt_login_toc_agree'
            >
              {__(
                'twoStepSafeComputerTitle',
                'Desea guardar este dispositivo para futuros inicios de sesión: al guardar ese dispositivo, el sistema no volverá a solicitar el código de seguridad, el dispositivo se guardará durante dos meses.'
              )}
              <a
                href='https://www.procreditoenlinea.com/habeas-data'
                target='_blank'
                className='ms-1 link-primary'
              >
                Politica de privacidad
              </a>
              .
            </label>
            {formik.touched.registerSafeComputer && formik.errors.registerSafeComputer && (
              <div className='fv-plugins-message-container' data-kt-buttons='true'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.registerSafeComputer}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-success w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>{__('nextButton', 'Continuar')}</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {__('singInButtonValidate', 'Validando')}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            type='button'
            onClick={onLogout}
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-success w-100 mb-5'
          >
            {__('cancelButton', 'Cancelar')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default TwoStepForm
