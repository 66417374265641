import {Column} from 'react-table'
import {ObligationClaim} from '../../core/_models'
import {CustomHeader} from './CustomHeader'
import {ActionsCell} from './ActionCell'
import {ReusableCell} from 'app/modules/utilities/ReusableCell'

const ObligationClaimColumns: ReadonlyArray<Column<ObligationClaim>> = [
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Tipo de Reclamo' className='min-w-125px' />
    ),
    id: 'claimType',
    Cell: ({...props}) => (
      <ReusableCell title={props.data[props.row.index].attributes.claimSubType.claimType.name} />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Tipo de Sub Reclamo' className='min-w-125px' />
    ),
    id: 'claimSubType',
    Cell: ({...props}) => (
      <ReusableCell title={props.data[props.row.index].attributes.claimSubType.name} />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Número de Documeto' className='min-w-125px' />
    ),
    id: 'id_number',
    Cell: ({...props}) => (
      <ReusableCell title={props.data[props.row.index].attributes.thirdParty.id_number} />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='statusTitle' className='min-w-155px' />
    ),
    id: 'step',
    Cell: ({...props}) => (
      <ReusableCell
        title={props.data[props.row.index].attributes.actualStepName.text}
        badge='info'
      />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='createdAtTitle' className='min-w-155px' />
    ),
    id: 'createdAt',
    Cell: ({...props}) => (
      <ReusableCell date={props.data[props.row.index].attributes.created_at} withHours={true} />
    ),
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='updatedAtTitle' className='min-w-155px' />
    ),
    id: 'updatedAt',
    Cell: ({...props}) => (
      <ReusableCell date={props.data[props.row.index].attributes.updated_at} withHours={true} />
    ),
  },
  {
    Header: (props) => <CustomHeader tableProps={props} className='min-w-125px' />,
    id: 'actions',
    Cell: ({...props}) => (
      <ActionsCell
        obligationClaim={props.data[props.row.index]}
        data={props}
        //@ts-ignore
        toggleModal={props.toggleModal}
      />
    ),
  },
]

export {ObligationClaimColumns}
