import React, {useEffect, useState} from 'react'
import {Formik, Form, FormikValues, Field} from 'formik'
import {useQueryResponse} from '../core/QueryResponseProvider'
import useTranslate from 'app/hooks/useTranslate'
import * as Yup from 'yup'
import {ErrorMessageField} from 'app/modules/utilities/alerts'
import {KTCard, KTCardBody, KTSVG} from '_metronic/helpers'

const validationSchema = Yup.object().shape({
  full_name: Yup.string()
    .matches(/^([a-zA-ZáéíñóúüÁÉÑÓÚÜ -]*)$/, 'specialCharError')
    .nullable(),
  id_number: Yup.number().typeError('idTypeNumericError').nullable(),
})

type OptionType = {
  label: string
  value: string
}

interface FormValues {
  full_name: string
  id_number: string
  person_type_id: OptionType | null
  city_id: OptionType | null
}

const initialValues: FormValues = {
  full_name: '',
  id_number: '',
  person_type_id: null,
  city_id: null,
}

const Search = () => {
  const __ = useTranslate()

  const {refetch, isLoading} = useQueryResponse()
  const [values, setValues] = useState<any>({})

  const objectValidate = (object: any) => {
    for (const property in object) {
      if (
        object[property] === '' ||
        object[property] === null ||
        object[property] === undefined ||
        object[property].length === 0
      ) {
        delete object[property]
      }
    }
    return object
  }

  const onSubmit = async (values: any, actions: FormikValues) => {
    let setFilters = {
      full_name: values.full_name,
      id_number: values.id_number,
      person_type_id: values.person_type_id?.value,
      city_id: values.city_id?.value,
    }

    //@ts-ignore
    refetch({
      filters: {...objectValidate(setFilters)},
    })
  }

  useEffect(() => {
    if (!values.id_number) {
      refetch()
    }
  }, [values])

  return (
    <KTCard>
      <KTCardBody>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div className='container-xxl'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              innerRef={(formikActions) =>
                formikActions ? setValues(formikActions.values) : setValues({})
              }
            >
              {(Props) => {
                return (
                  <Form action='app/modules/security/components/users/users-list/components/header/UsersListSearchComponent#'>
                    <div className='card'>
                      <div className='card-body'>
                        <div className='row mb-6 mt-1'>
                          <div className='col-md-3 fv-row'>
                            <Field
                              type='number'
                              className='form-control form-control-lg'
                              name='id_number'
                              maxLength={12}
                              placeholder={__('idNumber', 'Numero Documento')}
                            />

                            <ErrorMessageField field={'id_number'} />
                          </div>

                          <div className='col-md-5'>
                            <div className='d-flex align-items-center'>
                              <button
                                type='submit'
                                className='btn btn-lg btn-primary me-5'
                                disabled={isLoading || !Props.isValid}
                              >
                                {!isLoading && (
                                  <>
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen021.svg'
                                      className='svg-icon-2'
                                    />
                                    {__('findTitle', 'Buscar')}
                                  </>
                                )}
                                {isLoading && (
                                  <span className='indicator-progress' style={{display: 'block'}}>
                                    {__('processingTitle', 'Procesando')}...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                              {/* <a
                                id='kt_horizontal_search_advanced_link'
                                className='btn btn-link'
                                data-bs-toggle='collapse'
                                href='app/modules/security/components/users/users-list/components/header/UsersListSearchComponent#kt_advanced_search_form'
                              >
                                {__('advanceFindTitle', 'Búsqueda avanzada')}
                              </a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <ThirdPartyAdvancedSearch form={Props} /> */}
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export {Search}
