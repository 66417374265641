const reduceThirdParty = (thirdParties: any) => {
  let resultObj: any = []

  thirdParties.map((item: any) => {
    resultObj.push({
      id: item?.id || 0,

      debtorType: {
        label: item?.debtorType?.name ?? '',
        value: item?.debtorType?.id ?? '',
        allow_third_party_delete_on_obligation:
          item?.debtorType?.allow_third_party_delete_on_obligation || 0,
      },

      holderStatus: {
        label: item?.holderStatus?.name ?? '',
        value: item?.holderStatus?.id ?? '',
      },

      person_type_id: {
        label: item.personType?.name || '',
        value: item.personType?.id || '',
        need_holder_status: item.personType?.need_holder_status || false,
      },

      id_type_id: {
        label: item.idType?.name || '',
        value: item.idType?.id || '',
        need_verification: item.idType?.need_verification || false,
        only_number: item.idType?.only_number || false,
      },

      verification_digit: item.verification_digit,
      id_number: item.id_number,
      full_name: item.full_name,
      first_name: item.first_name,
      last_name: item.last_name,
      trading_name: item.trading_name,
      commercial_name: item.commercial_name,
      first_surname: item.first_surname,
      last_surname: item.last_surname,

      birthdate_at: item.birthdate_at,
      birthdate_city_id: {
        label: item.birthdateCity?.name || '',
        value: item.birthdateCity?.id || '',
      },
      birthdateCountry: {
        label: item.birthdateCity?.country?.name || '',
        value: item.birthdateCity?.country?.id || '',
      },
      birthdateState: {
        label: item.birthdateCity?.state?.name || '',
        value: item.birthdateCity?.state?.id || '',
      },
      expedition_at: item.expedition_at,
      expedition_city_id: {
        label: item.expeditionCity?.name || '',
        value: item.expeditionCity?.id || '',
      },
      expeditionCountry: {
        label: item.expeditionCity?.country?.name || '',
        value: item.expeditionCity?.country?.id || '',
      },
      expeditionState: {
        label: item.expeditionCity?.state?.name || '',
        value: item.expeditionCity?.state?.id || '',
      },
      gender_id: {
        label: item.gender?.name || '',
        value: item.gender?.id || '',
      },

      addresses: reduceThirdPartyAddresses(item.addresses),
      telephones: reduceThirdPartyTelephones(item.telephones),
      emails: item.emails || [],

      addressesToDelete: [],
      telephonesToDelete: [],
      emailsToDelete: [],
    })
  })

  return resultObj
}

const reduceThirdPartyAddresses = (addresses: any) => {
  let resultObj: Array<{}> = []

  addresses.map((item: any) => {
    resultObj.push({
      id: item.id || 0,
      additional: item.additional,
      address: item.address,
      street: item.street,
      number_1: item.number_1,
      number_2: item.number_2,
      neighborhood: item.neighborhood,

      is_active: item.is_active,

      is_main: item.is_main,
      is_residence: item.is_residence,
      is_work_address: item.is_work_address,
      is_correspondence_address: item.is_correspondence_address,

      city_id: {
        label: item.city?.name || '',
        value: item.city?.id || '',
      },
      country: {
        label: item.city?.country.name || '',
        value: item.city?.country.id || '',
      },
      state: {
        label: item.city?.state.name || '',
        value: item.city?.state.id || '',
      },

      street_type_id: {
        label: item.streetType?.name || '',
        value: item.streetType?.id || '',
      },
    })
  })

  return resultObj
}

const reduceThirdPartyTelephones = (telephones: any) => {
  let resultObj: Array<{}> = []

  telephones.map((item: any) => {
    resultObj.push({
      id: item.id || 0,
      additional: item.additional,
      is_active: item.is_active,
      is_main: item.is_main,
      number: item.number,

      city_id: {
        label: item.city?.name || '',
        value: item.city?.id || '',
      },
      country: {
        label: item.city?.country.name || '',
        value: item.city?.country.id || '',
      },
      state: {
        label: item.city?.state.name || '',
        value: item.city?.state.id || '',
      },

      telephone_type_id: {
        label: item.telephoneType?.name || '',
        value: item.telephoneType?.id || '',
        need_city: item.telephoneType?.need_city || false,
      },
    })
  })

  return resultObj
}

export default reduceThirdParty
