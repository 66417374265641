/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import useTranslate from 'app/hooks/useTranslate'
import {toAbsoluteUrl} from '_metronic/helpers'

const BannerPage: React.FC = () => {
  const __ = useTranslate()

  return (
    <>
      <div
        id='kt_carousel_1_carousel'
        className='carousel carousel-custom slide'
        data-bs-ride='carousel'
        data-bs-interval='8000'
      >
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='fs-4 fw-bolder pe-2'>
            {__('newnessWrapperTitle', 'Novedades Procrédito')}
          </span>
          <ol className='p-0 m-0 carousel-indicators carousel-indicators-dots'>
            <li
              data-bs-target='#kt_carousel_1_carousel'
              data-bs-slide-to='0'
              className='ms-1 active'
            ></li>
            <li data-bs-target='#kt_carousel_1_carousel' data-bs-slide-to='1' className='ms-1'></li>
          </ol>
        </div>

        <div className='carousel-inner pt-8'>
          <div className='carousel-item active'>
            <img
              alt='banner 1'
              className='h-700px w-auto'
              src={toAbsoluteUrl('/media/banner/banner.webp')}
            />
          </div>

          <div className='carousel-item'>
            <img
              alt='banner 2'
              className='h-700px w-auto'
              src={toAbsoluteUrl('/media/banner/banner1.png')}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const BannerWrapper: React.FC = () => {
  const __ = useTranslate()

  return (
    <>
      <PageTitle breadcrumbs={[]} description='Principal'>
        {__('principalTitle', 'Principal')}
      </PageTitle>
      <BannerPage />
    </>
  )
}

export {BannerWrapper}
