import React, {useEffect} from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'

import {useFormik, FormikHelpers} from 'formik'
import {MouseEventHandler} from 'react'
import useTranslate from 'app/hooks/useTranslate'

interface RestorePasswordFormProps {
  loading: boolean
  onSubmit: (values: any, options: FormikHelpers<any>) => Promise<void>
  onLogout: MouseEventHandler
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('signInEmailWrongFormatError').required('signInEmailRequiredError'),
})

const initialValues = {
  email: '',
}

const RestorePasswordForm: React.FC<RestorePasswordFormProps> = ({loading, onSubmit, onLogout}) => {
  const __ = useTranslate()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework py-5 py-lg-5'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>
            {__('passwordRestoreTitle', 'Recuperación de Contraseña')}
          </h1>

          <div className='alert alert-secondary d-flex align-items-center p-5 mb-10'>
            <div className='d-flex flex-column'>
              <span>
                {__(
                  'passwordRestoreDescription',
                  'Se enviara a su correo electronico una contraseña temporal para iniciar con el proceso de recuperación'
                )}
              </span>
            </div>
          </div>
        </div>

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold text-align-center'>{formik.status}</div>
          </div>
        ) : (
          ''
        )}

        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            {__('singInEmailLabel', 'Correo Electrónico')}
          </label>
          <input
            type='email'
            placeholder={__('singInEmailLabel', 'Correo Electrónico')}
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>
                  {__(formik.errors.email, 'El correo electronico no cumple con el formato')}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-success w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>{__('nextButton', 'Continuar')}</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {__('singInButtonValidate', 'Validando')}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            type='button'
            onClick={onLogout}
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-success w-100 mb-5'
          >
            {__('cancelButton', 'Cancelar')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default RestorePasswordForm
