import clsx from 'clsx'
import {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useSession} from '@alejandro.devop/redux-persistance'
import {useUpload} from 'app/providers/UploadProvider'
import useTranslate from 'app/hooks/useTranslate'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1 me-2'

const Topbar: FC = () => {
  const {store} = useSession()
  const __ = useTranslate()

  const {progressSlice, compressing, slicing, loading} = useUpload()

  return (
    <>
      <div className='d-flex align-items-stretch flex-shrink-0'>
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {(slicing || compressing || loading) && (
            <div className='d-flex align-items-center'>
              <div className='flex-grow-1'>
                <p className='fw-bolder text-gray-800 fs-9 text-center flex-fill'>
                  {loading && __('batchSendToServerTitle', 'Enviando Archivo')}

                  {(slicing || compressing) && __('batchStatusBarTitle', 'Comprimiendo Lotes')}
                </p>
                <div className='progress' style={{height: 8, width: 100}}>
                  <div
                    className='progress-bar progress-bar-animated progress-bar-striped bg-opacity-75 bg-primary'
                    role='progressbar'
                    style={{width: `${progressSlice}%`}}
                    aria-valuenow={25}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <div className='d-flex fw-bold text-hover-primary fs-7 item-align-center'>
              <KTSVG
                path='/media/icons/duotune/communication/com006.svg'
                className={toolbarButtonIconSizeClass}
              />
              <div className='d-none d-md-block d-lg-block d-print-block'>
                {store?.user.user_email}
              </div>
            </div>
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </>
  )
}

export {Topbar}
