import React from 'react'

import {useNavigate} from 'react-router-dom'
import LoginForm from './LoginForm'
import {FormikHelpers} from 'formik'
//@ts-ignore
import {usePost} from '@alejandro.devop/react-json-api-client'
import {USER_WORKFLOWS} from '../../../../../config/constants'
// @ts-ignore
import {useSession} from '@alejandro.devop/redux-persistance'
import reduceBackEndError from 'app/helpers/ReduceBackEndErrors'
import useTranslate from 'app/hooks/useTranslate'

import _ from 'lodash'

const Login: React.FC = () => {
  const [sendRequest, loading] = usePost('security.auth')
  const navigate = useNavigate()
  const {setAll} = useSession()
  const __ = useTranslate()

  const handleSubmit = async (
    values: {email: string; password: string},
    options: FormikHelpers<any>
  ) => {
    const {setStatus, setSubmitting} = options

    try {
      const {data: auth} = await sendRequest<{
        email: string
        password: string
        to_web: boolean
      }>({
        email: values.email,
        password: values.password,
        to_web: true,
      })

      const {id, attributes} = auth

      if (auth.errors) {
        setStatus(auth.errors.title)
      } else {
        if (attributes?.actualStepName.text === USER_WORKFLOWS.changePassword) {
          setAll({
            isChangePassword: true,
            token: attributes.api_token,
            tokenType: 'Bearer',
            user: {
              user_id: id,
              isRestorePassword: false,
              user_email: attributes.email,
            },
          })

          navigate('/auth/changePassword')
        } else {
          if (null !== attributes?.mainBranch && !attributes?.mainBranch.is_certificated) {
            if (
              _.filter(attributes.roles, (item) => {
                return item.allows_certifying === 1
              }).length ||
              _.filter(attributes.roles, (item) => {
                return item.name === process.env.REACT_APP_SUPER_ADMIN_ROL
              }).length
            ) {
              setAll({
                isRestorePassword: false,
                isSafeComputer: attributes?.safe_computer,
                token: attributes.api_token,
                tokenType: 'Bearer',
                isLogued: false,
                user: {
                  user_id: id,
                  rol: attributes.roles,
                  permissions: attributes.permissions,
                  user_email: attributes.email,
                  mainBranch: attributes.mainBranch,
                  main_branch_id: attributes.mainBranch?.id,
                  secondary_branch_id: attributes.secondaryBranch?.id,
                },
              })
              navigate('/auth/certification')
              return
            } else {
              setStatus(
                __(
                  'certifyingClientUserErrorTitle',
                  `El Administrador de ${attributes.mainBranch.commercial_name} debe realizar la certificación semestral`
                )
              )
              navigate('/auth/')
              return
            }
          }

          if (attributes?.safe_computer) {
            setAll({
              isChangePassword: false,
              isTowFactor: false,
              isLogued: true,
              isSafeComputer: true,
              token: attributes.api_token,
              tokenType: 'Bearer',
              isExpiredUserToken: false,
              user: {
                user_id: id,
                user_email: attributes.email,
                permissions: attributes.permissions,
                rol: attributes.roles,
                main_branch_id: attributes.mainBranch?.id,
                secondary_branch_id: attributes.secondaryBranch?.id,
              },
            })

            navigate('/general/banner')
          } else {
            setAll({
              isTowFactor: true,
              token: attributes.api_token,
              tokenType: 'Bearer',
              user: {
                user_id: id,
                isRestorePassword: false,
                user_email: attributes.email,
              },
            })

            navigate('/auth/twoStep')
          }
        }
      }
    } catch (e: any) {
      setStatus(reduceBackEndError(e))
      console.error(e)
      setSubmitting(false)
    }
  }

  return <LoginForm loading={loading} onSubmit={handleSubmit} />
}

export default Login
