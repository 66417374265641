import {FC, useEffect, useRef, useState} from 'react'
import Swal from 'sweetalert2'
import _ from 'lodash'

import {KTSVG} from '_metronic/helpers'

import {Formik, Form, FormikValues} from 'formik'

import {Schema, inits} from './core'
import {IObligationClaim} from './core/_models'

//@ts-ignore
import {usePost, usePatch} from '@alejandro.devop/react-json-api-client'

import {useQueryResponse} from '../list/core/QueryResponseProvider'

import useTranslate from 'app/hooks/useTranslate'

import {Modal, ModalFooter} from 'react-bootstrap'
import {ClaimInformation} from './components/ClaimInformation'
import reduceFormValue from 'app/helpers/ReduceFormValuesEmpty'
import reduceClaims from 'app/helpers/ReduceClaims'

type Props = {
  setModal: (a: boolean) => void
  showModal: boolean
  obligationClaim?: any
  obligation: any
}

const ManageObligationClaim: FC<Props> = ({setModal, showModal, obligationClaim}) => {
  const __ = useTranslate()

  const handleClose = () => setModal(false)
  const {refetch} = useQueryResponse()
  const formRef = useRef()

  const [isSubmitButton, setSubmitButton] = useState(false)
  const [currentSchema, setCurrentSchema] = useState(Schema[0])
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [initValues, setInitValues] = useState<IObligationClaim>(inits)

  const [sendCreateRequest, loadingCreate] = usePost('obligations.obligationsClaimCreate')
  const [sendUpdateRequest, loadingUpdate] = usePatch('obligations.obligationsClaimUpdate', {
    replacements: {id: obligationClaim?.id},
  })

  const handleSubmit = async (values: IObligationClaim, actions: FormikValues) => {
    try {
      let attributes = reduceFormValue(values)

      if (!obligationClaim.id) {
        const {data: createResponse} = await sendCreateRequest({
          data: {
            type: 'obligationClaims',
            attributes: attributes,
          },
        })

        if (createResponse.errors) {
          Swal.fire({
            toast: true,
            position: 'top',
            text: __('anErrorOcurredTitle', 'Ocurrió un error'),
            showConfirmButton: false,
            icon: 'error',
            timer: 2000,
          }).then(() => {
            console.error(createResponse.errors)
          })
        } else {
          Swal.fire({
            toast: true,
            position: 'top',
            text: __('rowCreatedSuccessFullyTitle', 'Registro Guardado Correctamente'),
            showConfirmButton: false,
            icon: 'info',
            timer: 2000,
          }).then(() => {
            setModal(false)
            refetch()
          })
        }
      }

      if (obligationClaim.id) {
        const {data: updateResponse} = await sendUpdateRequest({
          data: {
            type: 'obligationClaims',
            id: obligationClaim.id,
            attributes: attributes,
          },
        })

        if (updateResponse.errors) {
          Swal.fire({
            toast: true,
            position: 'top',
            text: __('anErrorOcurredTitle', 'Ocurrió un error'),
            showConfirmButton: false,
            icon: 'error',
            timer: 2000,
          }).then(() => {
            console.error(updateResponse.errors)
          })
        } else {
          Swal.fire({
            toast: true,
            position: 'top',
            text: __('rowUpdatedSuccessFullyTitle', 'Registro Actualizado Correctamente'),
            showConfirmButton: false,
            icon: 'info',
            timer: 2000,
          }).then(() => {
            setModal(false)
            refetch()
          })
        }
      }
    } catch (e: any) {
      Swal.fire({
        toast: true,
        position: 'top',
        text: __('anErrorOcurredTitle', 'Ocurrió un error'),
        showConfirmButton: false,
        icon: 'error',
        timer: 2000,
      })
      setSubmitButton(true)
      console.log('Error:', e)
    }
  }

  useEffect(() => {
    if (obligationClaim.id) {
      setInitValues(reduceClaims(obligationClaim.attributes))
      setIsEditing(true)
    }

    if (!obligationClaim.id) {
      setInitValues(inits)
      setIsEditing(false)
    }
  }, [obligationClaim])

  return (
    <>
      <Modal
        show={showModal}
        size='lg'
        centered
        scrollable
        autoFocus
        animation={true}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            {!obligationClaim.id
              ? __('claimAddTitle', 'Nuevo Reclamo')
              : __('claimEditTitle', 'Editar Reclamo')}
          </Modal.Title>
          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            data-bs-dismiss='modal'
            aria-label='Close'
            onClick={() => handleClose()}
          >
            <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon-muted' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Formik
            //@ts-ignore
            innerRef={formRef}
            validationSchema={currentSchema}
            enableReinitialize={true}
            initialValues={initValues}
            onSubmit={handleSubmit}
          >
            {(Props) => (
              <Form className='mx-auto mw-800px w-100' id='obligation_claim_form'>
                <ClaimInformation isEditing={isEditing} obligationClaim={obligationClaim ?? {}} />
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <ModalFooter>
          <div className='d-flex flex-stack'>
            <button
              type='button'
              className='btn btn-sm btn-secondary me-3'
              onClick={() => handleClose()}
            >
              <span className='indicator-label'>{__('cancelButton', 'Cancelar')}</span>
            </button>

            {obligationClaim?.attributes?.actualStepName.key !== 2 && (
              <button
                type='submit'
                className='btn btn-sm btn-primary me-3'
                disabled={loadingCreate || loadingUpdate}
                form='obligation_claim_form'
              >
                <span className='indicator-label'>
                  {!isSubmitButton && !loadingCreate && __('nextButton', 'Continuar')}
                  {(isSubmitButton || loadingCreate) && __('saveButton', 'Guardar')}
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </span>
              </button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export {ManageObligationClaim}
