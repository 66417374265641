import {useSession} from '@alejandro.devop/redux-persistance'
import {FC, createContext} from 'react'

const PermissionContext = createContext<any>([])

const PermissionProvider: FC = ({children}: any) => {
  const {store} = useSession()

  return (
    <PermissionContext.Provider
      value={{permissions: store?.user?.permissions, role: store?.user?.rol}}
    >
      {children}
    </PermissionContext.Provider>
  )
}

export {PermissionContext, PermissionProvider}
