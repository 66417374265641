import React from 'react'

import {useNavigate} from 'react-router-dom'
import ChangePasswordForm from './ChangePasswordForm'
import {FormikHelpers} from 'formik'
//@ts-ignore
import {usePost} from '@alejandro.devop/react-json-api-client'
// @ts-ignore
import {useSession} from '@alejandro.devop/redux-persistance'

const ChangePassword: React.FC = () => {
  const [sendRequest, loading] = usePost('security.changePassword')
  const navigate = useNavigate()
  const {clear, setAll, store = {}} = useSession()

  const handleSubmit = async (
    values: {password: string; changePassword: string; acceptTerms: boolean},
    options: FormikHelpers<any>
  ) => {
    const {setStatus, setSubmitting} = options
    try {
      const {data: auth} = await sendRequest<{
        data: any
      }>({
        data: {
          type: 'users',
          id: store?.user.user_id,
          attributes: {
            password: values.password,
            isRestorePassword: false,
            password_confirmation: values.changePassword,
            AcceptTerms: values.acceptTerms,
          },
        },
      })

      const {id, attributes} = auth

      if (auth.errors) {
        setStatus(auth.errors.title)
      } else {
        setAll({
          user: {
            user_id: id,
            restoredPassword: true,
            user_email: attributes.email,
          },
        })

        navigate('/auth/login')
      }
    } catch (e: any) {
      const {errorData} = e || {}
      const {data} = errorData
      const {errors} = data

      {
        errors ? setStatus(errors[0].detail) : setStatus(e.message)
      }

      setSubmitting(false)
    }
  }

  const onLogout = () => {
    clear({})
    navigate('/auth/login')
  }

  return <ChangePasswordForm loading={loading} onSubmit={handleSubmit} onLogout={onLogout} />
}

export default ChangePassword
