/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import {Link} from 'react-router-dom'
import {useSession} from '@alejandro.devop/redux-persistance'
import {useNavigate} from 'react-router-dom'
import useTranslate from 'app/hooks/useTranslate'
import useCheckRoleOrPermission from 'app/hooks/useCheckRoleOrPermission'

const HeaderUserMenu: React.FC = () => {
  const __ = useTranslate()
  const checkRoleOrPermission = useCheckRoleOrPermission()

  const {clear} = useSession()
  const navigate = useNavigate()

  const onLogout = () => {
    clear({})

    navigate('/auth/')
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <Link to={'/profile'} className='menu-link px-5'>
          {__('profileTitle', 'Mi Perfil')}
        </Link>
      </div>

      <div className='separator my-2'></div>

      {checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`) && (
        <>
          <div className='menu-item px-5'>
            <Link to={'/setting/general'} className='menu-link px-5'>
              {__('generalConfigurationTitle', 'Configuración General')}
            </Link>
          </div>

          <div className='menu-item px-5'>
            <Link to={'/security/users'} className='menu-link px-5'>
              {__('menuUser', 'Administración de Usuarios')}
            </Link>
          </div>

          <div className='menu-item px-5'>
            <Link to={'/security/roles'} className='menu-link px-5'>
              {__('menuRoles', 'Roles')}
            </Link>
          </div>

          <div className='separator my-2'></div>
        </>
      )}

      {/* <Languages /> */}

      <div className='menu-item px-5'>
        <a onClick={onLogout} className='menu-link px-5'>
          {__('signOutTitle', 'Cerrar Sesion')}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
