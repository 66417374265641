//@ts-ignore
import {useGet} from '@alejandro.devop/react-json-api-client'
import {useEffect, useRef} from 'react'

export const usePreviousValue = (value: undefined) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useGetMainBranches = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('customers.mainBranchesList', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetSecondaryBranches = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('customers.secondaryBranchesList', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetMainBranchesById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('customers.mainBranchesById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetThirdPartiesByFilters = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('customers.thirdPartyList', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetThirdPartiesByIdNumber = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('customers.thirdPartyGetByIdNumber', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetIfThirdPartyWasReporterByMainBranchId = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet(
    'customers.thirdPartyWasReporterByMainBranchId',
    {
      filters: {...filters},
    }
  )

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetMainBranchDownload = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('customers.mainBranchesDownload', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetSecondaryBranchDownload = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('customers.secondaryBranchesDownload', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}
