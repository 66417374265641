import {get} from 'http'
import React, {FC, FocusEventHandler, ReactNode} from 'react'
import Select, {
  ActionMeta,
  GetOptionLabel,
  GroupBase,
  Options,
  SelectComponentsConfig,
  StylesConfig,
  components,
} from 'react-select'
import {
  FormatOptionLabelContext,
  FormatOptionLabelMeta,
} from 'react-select/dist/declarations/src/Select'

const styles: StylesConfig<any> = {
  control: (css) => ({
    ...css,
    minWidth: 'auto',
    border: '1px solid #e4e6ef',
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
    fontSize: '1.1rem',
    fontWeight: 500,
    color: '#5e6278',
  }),
  menu: ({width, ...css}) => ({
    ...css,
    width: 'auto',
  }),
  option: (css) => ({
    ...css,
    width: 'auto',
    minWidth: 300,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
    fontSize: '1.1rem',
    fontWeight: 500,
    color: '#5e6278',
  }),
  singleValue: ({width, ...css}) => ({
    ...css,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
    fontWeight: 500,
    color: '#5e6278',
  }),
}

interface SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> {
  placeholder: string
  defaultValue?: string | object
  options: Option
  isMulti?: boolean
  name: string
  className: string
  isLoading?: boolean
  isDisabled?: boolean
  inputValue?: string
  value?: object | string
  selectedOption?: any
  tabSelectsValue?: any
  ref?: any
  formatOptionLabel?: (data: Option | null, context: FormatOptionLabelContext | any) => void
  getOptionLabel?: GetOptionLabel<Option>
  onChange: (option: Option | null, actionMeta: ActionMeta<Option>) => void
  components?: SelectComponentsConfig<Option | any, IsMulti | any, Group | any>
  onBlur?: FocusEventHandler<HTMLInputElement>
  isOptionDisabled?: ((option: Option, options: Options<Option>) => boolean | false) | undefined
}

const SingleSelect: FC<SelectProps<any>> = ({
  placeholder,
  defaultValue,
  options,
  onChange,
  isMulti = false,
  name,
  className = 'basic-single',
  isLoading = false,
  isDisabled = false,
  inputValue,
  value,
  selectedOption,
  tabSelectsValue,
  ref,
  formatOptionLabel,
  getOptionLabel,
  components,
  onBlur,
  isOptionDisabled,
}) => {
  const handleChange = (value: ActionMeta<any>) => {
    onChange(`${name}`, value)
  }

  return (
    <Select
      isMulti={isMulti}
      className={className}
      defaultValue={defaultValue}
      name={name}
      options={options}
      placeholder={placeholder}
      styles={styles}
      onChange={handleChange}
      isClearable
      isLoading={isLoading}
      isDisabled={isDisabled}
      value={value}
      tabSelectsValue={tabSelectsValue}
      ref={null}
      formatOptionLabel={formatOptionLabel}
      getOptionLabel={getOptionLabel}
      components={components}
      onBlur={onBlur}
      isOptionDisabled={isOptionDisabled}
    />
  )
}

export default SingleSelect
