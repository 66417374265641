import {FC, useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseLoading} from '../core/QueryResponseProvider'
import {ObligationClaim} from '../core/_models'
import {TableListLoading} from 'app/modules/utilities/loading/TableListLoading'
import {ObligationClaimPagination} from '../pagination/ObligationClaimPagination'
import {KTCard, KTCardBody} from '_metronic/helpers'
import {ObligationClaimColumns} from './columns/_columns'
import useTranslate from 'app/hooks/useTranslate'
import {PageTitle} from '_metronic/layout/core'

type Props = {
  obligationClaims: any
  toggleViewState: any
  toggleModal: any
}

const ObligationClaimTable: FC<Props> = ({obligationClaims, toggleViewState, toggleModal}) => {
  const __ = useTranslate()

  const columns = useMemo(() => ObligationClaimColumns, [])
  const isLoading = useQueryResponseLoading()
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    data: obligationClaims,
    columns,
    // @ts-ignore
    toggleViewState,
    toggleModal,
  })

  return (
    <>
      <PageTitle description={__('claimsTitle', 'Reclamos')}>
        {__('obligationModule', 'Obligaciones')}
      </PageTitle>
      <KTCard>
        <KTCardBody className='pt-0'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table table-row-bordered table-row-dashed gy-2 align-middle fw-bolder dataTable no-footer kanban-fixed-height'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  {headers.map((column: ColumnInstance<ObligationClaim>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<ObligationClaim>, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {__('noMarchingRecordsTitle', 'No matching records found')}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <ObligationClaimPagination />
          {isLoading && <TableListLoading />}
        </KTCardBody>
      </KTCard>
    </>
  )
}

export {ObligationClaimTable}
