//@ts-ignore
import {useGet} from '@alejandro.devop/react-json-api-client'
import {useMemo} from 'react'

export const useGetWorkflowStates = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.workflowStates')

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetAccountStatuses = (filters: null | object) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.accountStatuses', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetAccountStatusById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.accountStatusById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetCardClasses = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.cardClasses', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetCardClassById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.cardClassById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetCardFranchises = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.cardFranchises', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetCardFranchiseById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.cardFranchiseById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetCardStatuses = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.cardStatuses', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetCardStatusById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.cardStatusById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetAdjectives = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.adjectives', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetAdjectiveById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.adjectiveById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetContractTypes = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.contractTypes', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetContractTypeById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.contractTypeById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetCurrencyTypes = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.currencyTypes', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetCurrencyTypeById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.currencyTypeById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetDebtorTypes = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.debtorTypes', {
    filters: {
      is_active: 1,
    },
  })

  const dataInCache = useMemo(() => data, [data])

  return {
    loading,
    refresh,
    meta,
    data: dataInCache?.data,
  }
}

export const useGetDebtorTypeById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.debtorTypeById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetDeliveryTypes = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.deliveryTypes', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetHolderStatuses = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.holderStatuses', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetHolderStatusById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.holderStatusById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetNewnesses = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.newnesses', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetPaymentMethods = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.paymentMethods', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetPaymentMethodById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.paymentMethodById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetPaymentFrequencies = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.paymentFrequencies', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetPaymentFrequencyById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.paymentFrequencyById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetWarranties = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.warranties', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetWarrantyTypes = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.warrantyTypes', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetPortfolioStatuses = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.portfolioStatuses', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetObligationTypes = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.obligationTypes', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetQualifications = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.qualifications', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetClaimTypes = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.claimTypes', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

type claimSubTypeProps = {
  claim_type_id: string
}

export const useGetClaimSubTypes = ({claim_type_id}: claimSubTypeProps) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.claimSubTypes', {
    filters: {
      is_active: 1,
      claim_type_id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

type GetObligationClaimProps = {
  obligation_id: string
}

export const useGetObligationClaims = ({obligation_id}: GetObligationClaimProps) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.obligationsClaimList', {
    filters: {
      obligation_id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetObligationClaimWorkflowStates = () => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.obligationClaimWorkflowStates')

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

type GetConfigFieldTypesProps = {
  filters: object
}

export const useGetConfigFieldTypes = ({filters}: GetConfigFieldTypesProps) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.configFieldTypes')

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

type GetConfigFieldsProps = {
  parameters: object
}

export const useGetConfigFields = ({parameters}: GetConfigFieldsProps) => {
  const [data, {loading, refresh, meta}]: any = useGet(
    'obligations.configFields',
    parameters || null
  )

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetObligationsByObligationNumber = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet(
    'obligations.obligationGetByObligationNumber',
    {
      filters: {...filters},
    }
  )

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetObligationsByIdNumber = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.obligationGetByIdNumber', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetObligationControlErrors = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.obligationControlErrors', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetDownload = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.download', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetDeleteReasons = (filters: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.deleteReasons', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetUnlockedRates = (filters?: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('obligations.unlockedRates', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}
