import {FC, useEffect, useState} from 'react'
import useTranslate from 'app/hooks/useTranslate'
import _ from 'lodash'
import {useFormikContext} from 'formik'

interface Props {
  field?: any
}

const ErrorMessageField: FC<Props> = ({field}) => {
  const __ = useTranslate()
  const formik = useFormikContext()

  const [touchedValue, setTouchedValue] = useState<boolean>(false)
  const [errorValue, setErrorValue] = useState<string>('')

  useEffect(() => {
    let fields = field.replaceAll('[', '.').replaceAll(']', '').split('.')
    let positions: number = fields.length
    let touchedValue: any | boolean = formik.touched || []
    let errorValue: any | string = formik.errors || []

    _.forEach(fields, function (val: string | number) {
      touchedValue = touchedValue[val] || false
      errorValue = errorValue[val] || ''

      positions--

      if (!positions) {
        setTouchedValue(touchedValue)
        setErrorValue(errorValue)
      }
    })
  }, [formik.touched, formik.errors])

  return (
    <>
      {touchedValue && errorValue && (
        <div className='text-danger mt-2'>
          <div className='fv-plugins-message-container'>
            <span role='alert'>{__(errorValue, '')}</span>
          </div>
        </div>
      )}
    </>
  )
}

export default ErrorMessageField
