import React, {useContext} from 'react'
import {TranslationContext} from '../providers/TranslationProvider'

const useTranslate = () => {
  const {translations} = useContext(TranslationContext) || {}

  return React.useCallback((key: any, fallback: any) => {
    const translation = translations && translations[key]
    return translation || fallback || key
  }, [])
}

export default useTranslate
