import React from 'react'

import {useNavigate} from 'react-router-dom'
import TwoStepForm from './TwoStepForm'
import {FormikHelpers} from 'formik'
//@ts-ignore
import {usePost} from '@alejandro.devop/react-json-api-client'
// @ts-ignore
import {useSession} from '@alejandro.devop/redux-persistance'
import reduceBackEndError from 'app/helpers/ReduceBackEndErrors'

const TwoStep: React.FC = () => {
  const [sendRequest, loading] = usePost('security.verifyCode')
  const navigate = useNavigate()
  const {clear, setAll, store = {}} = useSession()

  const handleSubmit = async (
    values: {
      twoStepInput0: string
      twoStepInput1: string
      twoStepInput2: string
      twoStepInput3: string
      twoStepInput4: string
      twoStepInput5: string
      registerSafeComputer: boolean
    },
    options: FormikHelpers<any>
  ) => {
    const {setStatus, setSubmitting} = options
    try {
      const {data: auth} = await sendRequest<{
        data: any
      }>({
        data: {
          id: store?.user.user_id,
          type: 'users',
          attributes: {
            code:
              values.twoStepInput0 +
              values.twoStepInput1 +
              values.twoStepInput2 +
              values.twoStepInput3 +
              values.twoStepInput4 +
              values.twoStepInput5,
            registerSafeComputer: values.registerSafeComputer,
          },
        },
      })

      const {id, attributes} = auth

      if (auth.errors) {
        setStatus(auth.errors.title)
      } else {
        setAll({
          isChangePassword: false,
          isTowFactor: false,
          isLogued: true,
          token: attributes.api_token,
          tokenType: 'Bearer',
          isExpiredUserToken: false,
          user: {
            user_id: id,
            user_email: attributes.email,
            permissions: attributes.permissions,
            rol: attributes.roles,
            main_branch_id: attributes.mainBranch?.id,
            secondary_branch_id: attributes.secondaryBranch?.id,
          },
        })

        navigate('/general/banner')
      }
    } catch (e: any) {
      setStatus(reduceBackEndError(e))
      console.error(e)

      setSubmitting(false)
    }
  }

  const onLogout = () => {
    clear({})
    navigate('/auth/login')
  }

  return <TwoStepForm loading={loading} onSubmit={handleSubmit} onLogout={onLogout} />
}

export default TwoStep
