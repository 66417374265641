import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {ActivityDrawer, Main, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../assets/ts/components'

import {useSession} from '@alejandro.devop/redux-persistance'
import Pusher from 'pusher-js'
import {PermissionProvider} from 'app/providers/PermissionProvider'
import {UploadContextWrapper} from 'app/providers/UploadProvider'

const MasterLayout = () => {
  const {setAll, clear, store} = useSession()
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    const channel = process.env.REACT_APP_USER_SESSION_CHANNEL + store?.user?.user_id

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || 'notfound', {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER || 'notfound',
    })

    setInterval(() => {
      // Pusher.logToConsole = true

      let channelSuscribe = pusher.subscribe(channel)

      channelSuscribe.bind(
        process.env.REACT_APP_USER_SESSION_EXPIRED_EVENT || 'notfound',
        function () {
          clear({})

          setAll({
            isExpiredUserToken: true,
          })

          window.location.replace('/')
        }
      )
    }, 60000)
  }, [setAll, clear, store?.user?.user_id])

  return (
    <PageDataProvider>
      <PermissionProvider>
        <UploadContextWrapper>
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault />
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <HeaderWrapper />

              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                {/* <Toolbar /> */}
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                  <Content>
                    <Outlet />
                  </Content>
                </div>
              </div>
              {/* <Footer /> */}
            </div>
          </div>
          {/* begin:: Drawers */}
          <ActivityDrawer />
          <Main />
          <InviteUsers />
          <UpgradePlan />
          {/* end:: Modals */}
          <ScrollTop />'
        </UploadContextWrapper>
      </PermissionProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
