import React, {FC} from 'react'
import Select, {ActionMeta, GroupBase, StylesConfig} from 'react-select'

const styles: StylesConfig<any> = {
  control: (css) => ({
    ...css,
    maxHeight: 33,
    minHeight: 33,
    backgroundColor: 'rgba(255, 255, 255, 0.15)',

    minWidth: 'auto',
    // border: "0",
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
    fontSize: '0.925rem',
    fontWeight: 500,
    color: '#5e6278',
  }),
  indicatorSeparator: (css) => ({...css, marginTop: '5px'}),
  dropdownIndicator: (css) => ({
    ...css,
    padding: '3px 8px 5px',
  }),
  menu: ({width, ...css}) => ({
    ...css,
    width: 'auto',
  }),
  option: (css) => ({
    ...css,
    width: 300,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
    fontSize: '0.925rem',
    fontWeight: 500,
    color: '#5e6278',
    paddingTop: '5px',
  }),
  singleValue: ({width, ...css}) => ({
    ...css,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
    fontWeight: 500,
    fontSize: '0.925rem',
    color: '#5e6278',
  }),
  valueContainer: (css) => ({
    ...css,
    padding: '3px 8px 5px',
  }),
}

interface SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> {
  placeholder: string
  defaultValue?: string | object
  options: Option
  isMulti?: boolean
  name: string
  className: string
  isLoading?: boolean
  isDisabled?: boolean
  inputValue?: string
  value?: object | string
  selectedOption?: any
  tabSelectsValue?: any
  ref?: any

  onChange: (option: Option | null, actionMeta: ActionMeta<Option>) => void
}

/**
 * Simple Select Generic
 * @param prefix
 * @param defaultValue
 * @param options
 * @constructor
 */
const SingleSelect_sm: FC<SelectProps<any>> = ({
  placeholder,
  defaultValue,
  options,
  onChange,
  isMulti = false,
  name,
  className = 'basic-single',
  isLoading = false,
  isDisabled = false,
  inputValue,
  value,
  selectedOption,
  tabSelectsValue,
  ref,
}) => {
  const handleChange = (value: ActionMeta<any>) => {
    onChange(`${name}`, value)
  }

  return (
    <Select
      isMulti={isMulti}
      className={className}
      defaultValue={defaultValue}
      name={name}
      options={options}
      placeholder={placeholder}
      styles={styles}
      onChange={handleChange}
      isClearable
      isLoading={isLoading}
      isDisabled={isDisabled}
      value={value}
      tabSelectsValue={tabSelectsValue}
      ref={null}
    />
  )
}

export default SingleSelect_sm
