import React from 'react'

import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'

import {PrivateRoutes} from './PrivateRoutes'
import ErrorsPage from '../modules/errors/ErrorsPage'
import {AuthPage} from '../modules/security/AuthPage'
import {App} from '../App'
import {useSession} from '@alejandro.devop/redux-persistance'

const {PUBLIC_URL} = process.env

const AppRoutes: React.FC = () => {
  const {store = {}} = useSession()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {store?.token && store?.isLogued ? (
            <>
              <Route index element={<Navigate to='general/banner' />} />
              <Route path='/' element={<Navigate to='/general/banner' />} />
              <Route path='/*' element={<PrivateRoutes />} />

              <Route path='/error/*' element={<ErrorsPage />} />
            </>
          ) : (
            <>
              <Route path='/' element={<Navigate to='/auth/login' />} />
              <Route path='/auth/*' element={<AuthPage />} />

              <Route path='*' element={<Navigate to='/error/404' />} />
              <Route path='/error/404' element={<ErrorsPage />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
