import React from 'react'

import {useNavigate} from 'react-router-dom'
import RestorePasswordForm from './RestorePasswordForm'
import {FormikHelpers} from 'formik'
//@ts-ignore
import {usePost} from '@alejandro.devop/react-json-api-client'
// @ts-ignore
import {useSession} from '@alejandro.devop/redux-persistance'
import ReduceBackEndErrors from 'app/helpers/ReduceBackEndErrors'

const RestorePassword: React.FC = () => {
  const [sendRequest, loading] = usePost('security.restorePassword')
  const navigate = useNavigate()
  const {clear, setAll} = useSession()

  const handleSubmit = async (values: {email: string}, options: FormikHelpers<any>) => {
    const {setStatus, setSubmitting} = options

    try {
      const {data: auth} = await sendRequest<{
        data: any
      }>({
        data: {
          type: 'users',
          attributes: {
            email: values.email,
          },
        },
      })

      const {id, attributes} = auth

      if (auth.errors) {
        setStatus(auth.errors.title)
      } else {
        setAll({
          user: {
            user_id: id,
            isRestorePassword: true,
          },
        })

        navigate('/auth/login')
      }
    } catch (e: any) {
      setStatus(ReduceBackEndErrors(e))
      console.error(e)

      setSubmitting(false)
    }
  }

  const onLogout = () => {
    clear({})
    navigate('/auth/login')
  }

  return <RestorePasswordForm loading={loading} onSubmit={handleSubmit} onLogout={onLogout} />
}

export default RestorePassword
