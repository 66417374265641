import {toAbsoluteUrl} from '_metronic/helpers'
import useTranslate from 'app/hooks/useTranslate'

const VerifyEmail = () => {
  const __ = useTranslate()

  return (
    <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
      <div className='card card-flush w-100 py-5 flex-center flex-column-fluid'>
        <div className='card-body py-5 py-lg-5 mb-5'>
          <div className='mb-7'>
            <a href='/metronic8/demo1/../demo1/index.html' className=''></a>
          </div>

          <h2 className='fw-bolder text-gray-900 mb-5 text-center'>
            {__('verifyEmailWelcome', 'Bienvenido a PROCRÉDITO')}
          </h2>

          <div className='fw-semibold fs-6 text-gray-500 mb-7'>
            <div className='alert alert-success'>
              <div className='d-flex flex-column text-center'>
                <span>
                  {__('verifyEmailTitle', 'Hemos verificado su correo electronico correctamente.')}
                </span>
              </div>
            </div>

            <div className='alert alert-secondary'>
              <div className='d-flex flex-column'>
                <p
                  /* @ts-ignore */
                  style={{textAlign: 'justify', textJustify: 'inter-word'}}
                >
                  {__(
                    'verifyEmailDescription',
                    'Ya puedes iniciar sesión, recuerda que debes cambiar la contraseña para acceder a la plataforma, la contraseña se expira cada 2 meses por seguridad.'
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className='mb-0'>
            <img
              src={toAbsoluteUrl('/media/illustrations/dozzy-1/7-dark.png')}
              className='mw-100 mh-300px theme-light-show'
              alt=''
            />
          </div>

          <div className='mb-0'>
            <a href='/' className='btn btn-lg btn-success w-100 mb-5'>
              {__('singInButtonTitle', 'Iniciar Sesión')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmail
