import React, {useContext} from 'react'
import {PermissionContext} from 'app/providers/PermissionProvider'
import __ from 'lodash'

const useCheckModuleOrScreen = () => {
  const {permissions} = useContext(PermissionContext) || {}

  return React.useCallback((module: string = '', screen: string = '') => {
    let isAllow = false

    __.forEach(permissions, (permission: string, key: number) => {
      if (module && permission.includes(module + ':')) {
        isAllow = true
        return false
      }

      if (screen && permission.includes(':' + screen + ':')) {
        isAllow = true
        return false
      }
    })

    return isAllow
  }, [])
}

export default useCheckModuleOrScreen
