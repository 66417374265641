import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  stringifyRequestQuery,
} from '_metronic/helpers'
import {ObligationClaim} from './_models'
//@ts-ignore
import {useGet} from '@alejandro.devop/react-json-api-client'
import _ from 'lodash'
import useTranslate from 'app/hooks/useTranslate'
import {useQueryRequest} from 'app/modules/utilities/QueryRequestsProvider'

const QueryResponseContext = createResponseContext<ObligationClaim>(initialQueryResponse)

type Props = {
  children: any
}

const QueryResponseProvider: FC<Props> = ({children}) => {
  const __ = useTranslate()

  const requestContext = useQueryRequest()
  const {state} = requestContext
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  const [data, {loading: isFetching, refresh: refetch, meta}] = useGet(
    'obligations.obligationsClaimList',
    {
      currentPage: state.page,
      pageSize: state.items_per_page,
    }
  )

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const response: any = useMemo(() => {
    const totalPages =
      Math.ceil(
        meta?.page.total == undefined
          ? state.items_per_page
          : meta?.page.total / state.items_per_page
      ) || 1
    const links: {label: string; page: number}[] = []

    _.times(totalPages, (index) => {
      links.push({
        label: `${index + 1}`,
        page: index + 1,
      })
    })

    return {
      payload: {
        pagination: {
          page: state?.page,
          links: [
            {page: state.page - 1 > 0 ? state.page - 1 : 1, label: '&laquo; Previous'},
            ...links,
            {
              page: state.page + 1 <= totalPages ? state.page + 1 : state.page,
              label: 'Next &raquo;',
            },
          ],
        },
      },
      data: data?.data,
    }
  }, [data])

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }
  return response.payload.pagination
}

const useQueryReponseRefetch = () => {
  const {refetch} = useQueryResponse()
  return refetch
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryReponseRefetch,
  useQueryResponseLoading,
}
