import React, {createContext, FC, useEffect, useState} from 'react'
import {useGetTranslations} from '../services/SettingService'

const LANGUAGE_CONFIG_KEY = process.env.REACT_APP_LANGUAGE_CONFIG_KEY || 'languageConfig'
const getSelectedLang = localStorage.getItem(LANGUAGE_CONFIG_KEY) || '{}'

type Props = {
  selectedLang: 'en' | 'es'
}

const initialState: Props = {
  selectedLang: JSON.parse(getSelectedLang)['selectedLang'] || 'es',
}

export function setLanguage(lang: string) {
  localStorage.setItem(LANGUAGE_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
}

const getConfig = () => {
  const ls = localStorage.getItem(LANGUAGE_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

export const TranslationContext = createContext<any>([])

export const TranslationProvider: FC = ({children}: any) => {
  const [locale, setLocale] = useState('es')
  const lang = getConfig()
  const {data, refresh} = useGetTranslations(locale)

  useEffect(() => {
    setLanguage(locale)
    refresh()
  }, [locale])

  return (
    <TranslationContext.Provider value={{translations: data?.attributes, setLocale, lang}}>
      {children}
    </TranslationContext.Provider>
  )
}
