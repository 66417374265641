import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {ClaimListWrapper} from 'app/modules/claims/list/ObligationClaimList'
import {BannerWrapper} from 'app/pages/banner/BannerWrapper'

const PrivateRoutes = () => {
  const SecurityPage = lazy(() => import('../modules/security/SecurityPage'))
  const SettingPage = lazy(() => import('../modules/setting/SettingPage'))
  const ProfilePage = lazy(() => import('../modules/security/ProfilePage'))
  const ObligationsPage = lazy(() => import('../modules/obligations/ObligationsPage'))
  const CustomerPage = lazy(() => import('../modules/customer/CustomerPage'))
  const ConsultPage = lazy(() => import('../modules/consults/ConsultPage'))
  const SVDPage = lazy(() => import('../modules/svd/SVDPage'))
  const ResportPage = lazy(() => import('../modules/reports/ReportPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='general/banner' element={<BannerWrapper />} />
        <Route path='general/dashboard' element={<DashboardWrapper />} />
        <Route path='security/*' element={<SecurityPage />} />
        <Route path='setting/*' element={<SettingPage />} />
        <Route path='profile/*' element={<ProfilePage />} />
        <Route path='obligations/*' element={<ObligationsPage />} />
        <Route path='claims/' element={<ClaimListWrapper />} />
        <Route path='customer/*' element={<CustomerPage />} />
        <Route path='consult/*' element={<ConsultPage />} />
        <Route path='securityVirtualDrive/*' element={<SVDPage />} />
        <Route path='reports/*' element={<ResportPage />} />

        <Route path='*' element={<Navigate to='/error/404' />} />

        <Route index element={<BannerWrapper />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
