import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'

import {useFormik, FormikHelpers} from 'formik'
import {MouseEventHandler} from 'react'
import {PasswordMeterComponent} from '../../../../../../_metronic/assets/ts/components'
import {useNavigate} from 'react-router-dom'
// @ts-ignore
import {useSession} from '@alejandro.devop/redux-persistance'
import useTranslate from 'app/hooks/useTranslate'

interface ChangePasswordFormProps {
  loading: boolean
  onSubmit: (values: any, options: FormikHelpers<any>) => Promise<void>
  onLogout: MouseEventHandler
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'changePasswordPasswordErrorMinChrsDescription')
    .required('changePasswordPasswordRequiredField')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@?/#\$%\^&\*])(?=.{8,})/,
      'changePasswordPasswordErrorDescription'
    ),
  changePassword: Yup.string()
    .required('changePasswordConfirmPasswordRequireField')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'changePasswordConfirmPasswordErrorDescription'
      ),
    }),
})

const initialValues = {
  password: '',
  changePassword: '',
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({loading, onSubmit, onLogout}) => {
  const {setAll, store = {}} = useSession()
  const navigate = useNavigate()
  const __ = useTranslate()
  const [textTypePassword, setTextTypePassword] = useState<string>('password')
  const [textTypeConfirmPassword, setTextTypeConfirmPassword] = useState<string>('password')

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    if (!store?.isChangePassword && !store.token) {
      setAll({})
      navigate('/auth')
    }
  }, [])

  return (
    <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
      <form
        className='form w-100 py-5 py-lg-5'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>
            {__('changePasswordTitle', 'Configura una nueva contraseña')}
          </h1>
        </div>

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold text-align-center'>{formik.status}</div>
          </div>
        ) : (
          ''
        )}

        <div className='mb-10 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {__('singInPasswordLabel', 'Contraseña')}
            </label>

            <div className='position-relative mb-3'>
              <div className='input-group'>
                <input
                  type={textTypePassword}
                  placeholder={__('singInPasswordLabel', 'Contraseña')}
                  minLength={8}
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control form-control-lg',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />

                <span
                  className='btn btn-primary'
                  onClick={(el) => {
                    el.preventDefault()
                    if (textTypePassword === 'password') {
                      setTextTypePassword('text')
                    }

                    if (textTypePassword === 'text') {
                      setTextTypePassword('password')
                    }
                  }}
                >
                  <i
                    className={`bi bi-eye-${
                      textTypePassword === 'password' ? 'slash' : 'fill'
                    } fs-2`}
                  ></i>
                </span>
              </div>

              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>
                      {__(
                        formik.errors.password,
                        'La contraseña debe contener como mínimo 8 caracteres y maximo 10 caracteres, al menos una letra mayúscula, al menos una letra minúscula, al menos un número y al menos un caracter especial como por ejemplo: !AvdD234/{'
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
          </div>

          <div className='text-muted'>
            {__(
              'changePasswordPasswordErrorDescription',
              'La contraseña debe contener como mínimo 8 caracteres, al menos una letra mayúscula, al menos una letra minúscula, al menos un número y al menos un caracter especial como por ejemplo: !AvdD234/}'
            )}
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {__('singInConfirmPasswordLabel', 'Confirmar Contraseña')}
          </label>

          <div className='input-group'>
            <input
              type={textTypeConfirmPassword}
              placeholder={__('singInConfirmPasswordLabel', 'Confirmar Contraseña')}
              autoComplete='off'
              minLength={8}
              {...formik.getFieldProps('changePassword')}
              className={clsx(
                'form-control form-control-lg',
                {
                  'is-invalid': formik.touched.changePassword && formik.errors.changePassword,
                },
                {
                  'is-valid': formik.touched.changePassword && !formik.errors.changePassword,
                }
              )}
            />

            <span
              className='btn btn-primary'
              onClick={(el) => {
                el.preventDefault()
                if (textTypeConfirmPassword === 'password') {
                  setTextTypeConfirmPassword('text')
                }

                if (textTypeConfirmPassword === 'text') {
                  setTextTypeConfirmPassword('password')
                }
              }}
            >
              <i
                className={`bi bi-eye-${
                  textTypeConfirmPassword === 'password' ? 'slash' : 'fill'
                } fs-2`}
              ></i>
            </span>
          </div>

          {formik.touched.changePassword && formik.errors.changePassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>
                  {__(
                    formik.errors.changePassword,
                    'La contraseña debe contener como mínimo 8 caracteres y maximo 10 caracteres, al menos una letra mayúscula, al menos una letra minúscula, al menos un número y al menos un caracter especial como por ejemplo: !AvdD234*{'
                  )}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-success w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>{__('nextButton', 'Continuar')}</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {__('singInButtonValidate', 'Validando')}...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            type='button'
            onClick={onLogout}
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-success w-100 mb-5'
          >
            {__('cancelButton', 'Cancelar')}
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChangePasswordForm
