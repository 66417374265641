//@ts-ignore
import {useGet} from '@alejandro.devop/react-json-api-client'
import {date} from 'yup'

type GetStatesProps = {
  country_id: string
}

type GetCitiesProps = {
  country_id: string
  state_id: string
}

type GetEconomicActivitiesProps = {
  economic_sector_id: string
}

export const useGetCountries = () => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.countries', {
    filters: {
      is_active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetStates = ({country_id}: GetStatesProps) => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.statesByFilters', {
    filters: {
      country_id,
    },
    sorts: ['name'],
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data || [],
  }
}

export const useGetCities = ({country_id, state_id}: GetCitiesProps) => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.citiesByFilters', {
    filters: {
      country_id,
      state_id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data || [],
  }
}

export const useGetPersonTypes = () => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.personTypes')

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetPersonTypeById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.personTypeById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

type GetIdTypesProps = {
  person_type_id: string | number | null
}
export const useGetIdTypes = ({person_type_id}: GetIdTypesProps) => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.idTypes', {
    filters: {
      person_type_id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetIdTypeById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.idTypeById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetStreetTypes = () => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.streetTypes')

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetStreetTypeById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.streetTypeById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetUserByResourceId = () => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.userByResourceId')

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetTelephoneTypes = () => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.telephoneTypes', {
    filters: {
      active: 1,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetTelephoneTypeById = (id: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.telephoneTypeById', {
    replacements: {
      id: id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetTranslations = (locale?: string, group?: string) => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.translationByName', {
    replacements: {
      locale: locale || 'es',
      group: group || 'frontend',
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetLanguages = () => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.languages')

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetEconomicSectors = () => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.economicSectors')

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetEconomicActivities = ({economic_sector_id}: GetEconomicActivitiesProps) => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.economicActivities', {
    filters: {
      economic_sector_id,
    },
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

export const useGetCertificationTypes = () => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.certificationTypes')

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}

type TelephoneCodeProps = {
  country_id: string
}

export const useGetGenders = (filters?: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('setting.gendersList', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}
