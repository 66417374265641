export const USER_WORKFLOWS = Object.freeze({
  changePassword: 'Cambio de Contraseña',
})

export const STATUS_RESPONSE = Object.freeze({
  unauthorized: 401,
})

export const CUSTOMER_CONSTANTS = Object.freeze({
  TYPE_MAIN: 'MAIN_BRANCH',
  TYPE_SECONDARY: 'SECONDARY_BRANCH',
})

export const PERMISSIONS = Object.freeze({
  security: {
    roles: {
      index: 'security:roles:index-web',
      create: 'security:roles:create-web',
      update: 'security:roles:update-web',
      delete: 'security:roles:delete-web',
    },
    users: {
      index: 'security:users:index-web',
      create: 'security:users:create-web',
      update: 'security:users:update-web',
      delete: 'security:users:delete-web',
      active: 'security:users:active-web',
      inactive: 'security:users:inactive-web',
      resendEmail: 'security:users:resendEmail-web',
      download: 'security:users:download-web',
    },
  },
  customer: {
    thirdParties: {
      index: 'customer:thirdParties:index-web',
      create: 'customer:thirdParties:create-web',
      update: 'customer:thirdParties:update-web',
      active: 'customer:thirdParties:active-web',
      inactive: 'customer:thirdParties:inactive-web',
    },
    mainBranches: {
      index: 'customer:mainBranches:index-web',
      create: 'customer:mainBranches:create-web',
      update: 'customer:mainBranches:update-web',
      active: 'customer:mainBranches:active-web',
      inactive: 'customer:mainBranches:inactive-web',
      download: 'customer:mainBranches:download-web',
    },
    secondaryBranches: {
      index: 'customer:secondaryBranches:index-web',
      create: 'customer:secondaryBranches:create-web',
      update: 'customer:secondaryBranches:update-web',
      active: 'customer:secondaryBranches:active-web',
      inactive: 'customer:secondaryBranches:inactive-web',
      download: 'customer:secondaryBranches:download-web',
    },
    mainBranchCertifications: {
      index: 'customer:mainBranchCertifications:index-web',
      create: 'customer:mainBranchCertifications:create-web',
      update: 'customer:mainBranchCertifications:update-web',
    },
  },
  consults: {
    creditHistoryBehavior: 'consult:consults:creditHistoryBehavior-web',
    queryScoreDetails: 'consult:consults:queryScoreDetails-web',
    locate: 'consult:consults:locate-web',
  },
  obligations: {
    obligations: {
      index: 'obligation:obligations:index-web',
      create: 'obligation:obligations:create-web',
      read: 'obligation:obligations:read-web',
      update: 'obligation:obligations:update-web',
      updateThirdParty: 'obligation:obligations:updateThirdParty-web',
      claimCreate: 'obligation:obligations:claimCreate-web',
      claimUpdate: 'obligation:obligations:claimUpdate-web',
      vectorUpdate: 'obligation:obligations:vectorUpdate-web',
      vectorDelete: 'obligation:obligations:vectorDelete-web',
      unlock: 'obligation:obligations:unlock-web',
      download: 'obligation:obligations:download-web',
      delete: 'obligation:obligations:download-web',
    },
    obligationControl: {
      index: 'obligation:obligationControls:index-web',
      create: 'obligation:obligationControls:create-web',
      read: 'obligation:obligationControls:read-web',
      update: 'obligation:obligationControls:update-web',
      batch: 'obligation:obligationControls:batch-web',
    },
  },
  setting: {
    countries: {
      index: 'setting:countries:index-web',
      active: 'setting:countries:active-web',
      inactive: 'setting:countries:inactive-web',
    },
    configs: {
      platformConfig: 'setting:configs:platformConfig-web',
      create: 'setting:configs:create-web',
      update: 'setting:configs:update-web',
    },
  },
  svd: {
    svdControls: {
      index: 'svd:svdControls:index-web',
      create: 'svd:svdControls:create-web',
      update: 'svd:svdControls:update-web',
      uploadResponse: 'svd:svdControls:uploadResponse-web',
    },
    download: {
      index: 'svd:svdControls:index-web',
      create: 'svd:svdControls:create-web',
      update: 'svd:svdControls:update-web',
      uploadResponse: 'svd:svdControls:uploadResponse-web',
    },
  },
  report: {
    reports: {
      index: 'report:reports:index-web',
      create: 'report:reports:create-web',
      read: 'report:reports:read-web',
      update: 'report:reports:update-web',
    },
  },
})

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]
export const SHORT_MONTHS = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
]
export const WEEK_DAYS = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
export const SHORT_WEEK_DAYS = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']
