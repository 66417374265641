import {FC} from 'react'

import {KTSVG} from '_metronic/helpers'

type Props = {
  show: any
  title?: string
  description?: string
}

const AlertInfo: FC<Props> = ({show, title, description}) => {
  return (
    <>
      {show && (
        <div className='alert bg-light-primary d-flex flex-center flex-column py-5 px-5 px-lg-5'>
          <KTSVG
            path='/media/icons/duotune/general/gen045.svg'
            className='svg-icon-muted svg-icon-4hx svg-icon-primary'
          />

          <div className='text-center'>
            <h1 className='fw-bold mb-5'>{title}</h1>

            <div className='separator separator-dashed border-primary opacity-25 mb-5'></div>

            <div
              className='mb-9 text-dark'
              /* @ts-ignore */
              style={{textAlign: 'justify', textJustify: 'inter-word'}}
            >
              {description}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AlertInfo
