import React from 'react'

type LinksType = {
  label: string
  page: number
}

type UsePageResumeConfig = {
  links?: (LinksType | any)[]
  currentPage?: number
  max?: number
}

const usePaginationResume = (config?: UsePageResumeConfig) => {
  const {links, currentPage = 1, max = 8} = config || {}
  const pages = React.useMemo(() => {
    let newLinks: LinksType[] = []
    // First extract the links that are always present, like previous and next
    const [previous, ...rest] = links || []
    const next = rest?.pop()
    const lastPage = rest.length
    // links are resumed only if the pages are greather the half of limit.
    if (rest.length > max) {
      // Two virtual pivot points are created to control the pages displayed
      const pivotPointLeft = Math.ceil(max / 2)
      const pivotPointRight = rest.length - max
      let firstIndex = 0
      let lastIndex = max
      // Small indicator that there are more pages ahead
      let fillRight: any = {page: null, label: '...'}

      if (currentPage > pivotPointLeft && currentPage <= pivotPointRight) {
        const offset = currentPage - pivotPointLeft
        firstIndex = offset
        lastIndex = firstIndex + max
      } else if (currentPage > pivotPointRight) {
        firstIndex = pivotPointRight
        lastIndex = rest.length
        fillRight = null
      }
      newLinks = [...rest.slice(firstIndex, lastIndex)]
      if (fillRight) {
        newLinks.push(fillRight)
      }
      // Now we need to append an option to go last and first
      if (currentPage > 1) {
        newLinks = [{page: 1, label: 'Primer'}, previous, ...newLinks]
      }
      if (currentPage < rest.length) {
        newLinks = [...newLinks, next, {page: lastPage, label: 'Última'}]
      }
    } else {
      newLinks = [
        {page: 1, label: 'Primer'},
        previous,
        ...rest,
        next,
        {page: lastPage, label: 'Última'},
      ]
    }

    return newLinks
  }, [links, currentPage, max])
  return pages
}

export default usePaginationResume
