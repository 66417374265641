import reduceThirdParty from './ReduceThirdParty'

const reduceClaims = (obligationClaim: any) => {
  let objResult: any = {}

  objResult = {
    claim_sub_type_id: {
      label: obligationClaim.claimSubType?.name || '',
      value: obligationClaim.claimSubType?.id || '',
    },
    claim_type_id: {
      label: obligationClaim.claimSubType?.claimType.name || '',
      value: obligationClaim.claimSubType?.claimType.id || '',
    },
    main_branch_id: {
      label: obligationClaim.mainBranch?.commercial_name || '',
      value: obligationClaim.mainBranch?.id || '',
    },
    workflow_status_id: {
      label: obligationClaim.actualStepName?.text || '',
      value: obligationClaim.actualStepName?.key || '',
    },
    obligation_id: obligationClaim.obligations?.map((item: any, value: any) => {
      return {
        label: item.obligation_number,
        value: item.id,
        name: item.name,
        commercial_name: item.commercial_name,
        badge: item.badge,
      }
    }),
    thirdParty: reduceThirdParty([obligationClaim?.thirdParty])[0],
  }

  return objResult
}

export default reduceClaims
