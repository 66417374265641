import _ from 'lodash'

export default (errors: object | any) => {
  const {errorData} = errors || {}
  const {data = {}} = errorData
  const {errors: errs = {}} = data

  let newErrors: any = []

  if (typeof errs === 'object' && Object.keys(errs).length) {
    _.forEach(errs, function (o: any | object) {
      const {detail = {}} = o

      if (detail.indexOf('{') != -1) {
        let details = JSON.parse(detail)

        _.forEach(details, function (er: any) {
          _.forEach(er, function (e: string) {
            newErrors.push(e)
          })
        })

        return true
      }

      if (typeof detail === 'string' && detail.indexOf('{') == -1) {
        newErrors.push(detail)
        return true
      }
    })

    return newErrors.join(' ,')
  }

  return 'Ocurrio un error interno, comuniquese con el administrador de su cuenta.'
}
