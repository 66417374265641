import ReactDOM from 'react-dom'
// Axios
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'

import SessionProvider from '@alejandro.devop/redux-persistance'

// Apps
import AppWrapper from './app/core/app-wrapper'

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import AppRoutes from './app/routing/AppRoutes'
import {TranslationProvider} from './app/providers/TranslationProvider'

Chart.register(...registerables)

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <SessionProvider driver={'localStorage'} devMode={false}>
      <AppWrapper>
        <TranslationProvider>
          <AppRoutes />
        </TranslationProvider>
      </AppWrapper>
    </SessionProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root')
)
