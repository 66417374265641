import {FC, useEffect, useMemo, useState} from 'react'
import {ListViewProvider} from './core/ListViewProvider'
import {QueryResponseProvider, useQueryResponseData} from './core/QueryResponseProvider'
import {ObligationClaimTable} from './table/ObligationClaimTable'
import {Toolbar} from './header/ToolBar'
import {useNavigate} from 'react-router-dom'

import useCheckRoleOrPermission from 'app/hooks/useCheckRoleOrPermission'
import {PERMISSIONS} from 'app/config/constants'
import {QueryRequestProvider} from 'app/modules/utilities/QueryRequestsProvider'
import {ManageObligationClaim} from '../manage/ManageObligationClaim'
import {Search} from './header/Search'

const ClaimList = () => {
  const isAllow = useCheckRoleOrPermission()
  const navigate = useNavigate()

  const obligationClaims = useQueryResponseData()
  const data = useMemo(() => obligationClaims, [obligationClaims])
  const [viewState, setViewState] = useState('')
  const [itemForUpdate, setItemForUpdate] = useState({})
  const [modal, setModal] = useState<boolean>(false)

  const toggleModal = (state: boolean, obligationClaim?: any) => {
    if (obligationClaim) {
      setItemForUpdate(obligationClaim)
    } else {
      setItemForUpdate('')
    }
    setModal(state)
  }

  const toggleViewState = (state: any, obligation?: any) => {
    if (obligation) {
      setItemForUpdate(obligation)
    }
    setViewState(state)
  }

  useEffect(() => {
    if (
      !isAllow(
        `${process.env.REACT_APP_SUPER_ADMIN_ROL}:${PERMISSIONS.obligations.obligations.claimCreate}`
      )
    )
      navigate('/error/403')
  }, [])

  return (
    <>
      {(() => {
        switch (viewState) {
          default:
            return (
              <>
                <Search />

                <div className='d-flex flex-wrap flex-stack pb-7 justify-content-end mt-5'>
                  {isAllow(
                    `${process.env.REACT_APP_SUPER_ADMIN_ROL}:${PERMISSIONS.obligations.obligations.claimCreate}`
                  ) && <Toolbar toggleModal={toggleModal} />}
                </div>
                <ObligationClaimTable
                  obligationClaims={data}
                  toggleViewState={toggleViewState}
                  toggleModal={toggleModal}
                />
              </>
            )
        }
      })()}

      <ManageObligationClaim
        showModal={modal}
        setModal={toggleModal}
        obligationClaim={itemForUpdate ?? {}}
        obligation={itemForUpdate}
      />
    </>
  )
}

const ClaimListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ClaimList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ClaimListWrapper}
