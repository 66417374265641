import {AsideMenuItem} from './AsideMenuItem'
import useTranslate from '../../../../app/hooks/useTranslate'
import useCheckModuleOrScreen from 'app/hooks/useCheckModuleOrScreen'
import useCheckRoleOrPermission from 'app/hooks/useCheckRoleOrPermission'
import {PERMISSIONS} from 'app/config/constants'

export function AsideMenuMain() {
  const __ = useTranslate()
  const checkModuleOrScreen = useCheckModuleOrScreen()
  const checkRoleOrPermission = useCheckRoleOrPermission()

  return (
    <>
      <AsideMenuItem
        to='/general/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={__('dashboard_menu', 'Dashboard')}
        // fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-1'></div>
        </div>
      </div>

      {(checkModuleOrScreen('consult') ||
        checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-success text-uppercase fs-9 ls-3'>
                {__('menuConsults', 'Consultas')}
              </span>
            </div>
          </div>

          {checkRoleOrPermission(
            `${process.env.REACT_APP_SUPER_ADMIN_ROL}:${PERMISSIONS.consults.creditHistoryBehavior}`
          ) && (
            <AsideMenuItem
              to='consult/historyBehavior'
              title={__('menuHistoryBehavior', 'Historial + Comportamiento')}
              icon='/media/icons/duotune/files/fil024.svg'
              hasBullet={false}
            />
          )}

          {checkRoleOrPermission(
            `${process.env.REACT_APP_SUPER_ADMIN_ROL}:${PERMISSIONS.consults.queryScoreDetails}`
          ) && (
            <AsideMenuItem
              to='consult/scoreDetail'
              title={__('menuScoreDetail', 'Score + Detalle')}
              icon='/media/icons/duotune/files/fil024.svg'
              hasBullet={false}
            />
          )}

          {checkRoleOrPermission(
            `${process.env.REACT_APP_SUPER_ADMIN_ROL}:${PERMISSIONS.consults.locate}`
          ) && (
            <AsideMenuItem
              to='consult/locate'
              title={__('menuLocate', 'Localiza')}
              icon='/media/icons/duotune/files/fil024.svg'
              hasBullet={false}
            />
          )}
        </>
      )}

      {(checkModuleOrScreen('obligation') ||
        checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-success text-uppercase fs-9 ls-3'>
                {__('menuObligations', 'Obligaciones')}
              </span>
            </div>
          </div>

          {(checkModuleOrScreen('', 'obligations') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='obligations/lists'
              title={__('menuObligations', 'Obligaciones')}
              icon='/media/icons/duotune/files/fil001.svg'
              hasBullet={false}
            />
          )}

          {(checkModuleOrScreen('', 'thirdParties') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='customer/thirdParties'
              title={__('menuThirdParty', 'Terceros')}
              icon='/media/icons/duotune/communication/com014.svg'
              hasBullet={false}
            />
          )}

          {(checkModuleOrScreen('', 'obligationControls') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='obligations/batch'
              title={__('menuBatchReport', 'Reporte en Lotes')}
              icon='/media/icons/duotune/files/fil023.svg'
              iconClass='svg-icon-1ix svg-icon-primary'
              hasBullet={false}
            />
          )}
        </>
      )}

      {(checkModuleOrScreen('svd') ||
        checkModuleOrScreen('General') ||
        checkModuleOrScreen('report') ||
        checkModuleOrScreen('download') ||
        checkModuleOrScreen('', 'obligationClaims') ||
        checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-success text-uppercase fs-9 ls-3'>
                {__('menuUtilities', 'Utilidades')}
              </span>
            </div>
          </div>
          {(checkModuleOrScreen('', 'svdControls') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='securityVirtualDrive/lists'
              title={__('menuSVU', 'Unidad Virtual Segura')}
              icon='/media/icons/duotune/general/gen047.svg'
              hasBullet={false}
            />
          )}
          {(checkModuleOrScreen('', 'mainBranchCertifications') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='customer/certifications'
              title={__('menuCertified', 'Certificacion Semestral')}
              icon='/media/icons/duotune/general/gen048.svg'
              hasBullet={false}
            />
          )}
          {(checkModuleOrScreen('', 'reports') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='reports'
              title={__('menuInformZone', 'Zona Informes')}
              icon='/media/icons/duotune/graphs/gra010.svg'
              hasBullet={false}
            />
          )}
          {(checkModuleOrScreen('', 'downloadControls') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='securityVirtualDrive/downloads'
              title={__('menuDownloadZone', 'Zona Descargas')}
              icon='/media/icons/duotune/files/fil021.svg'
              hasBullet={false}
            />
          )}

          {(checkModuleOrScreen('', 'obligationClaims') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='claims/'
              title={__('claimsTitle', 'Reclamaciones')}
              icon='/media/icons/duotune/general/gen055.svg'
              hasBullet={false}
            />
          )}
        </>
      )}
      {(checkModuleOrScreen('customer') ||
        checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-3'>
              <span className='menu-section text-success text-uppercase fs-9 ls-3'>
                {__('menuClients', 'Empresas')}
              </span>
            </div>
          </div>
          {(checkModuleOrScreen('', 'mainBranches') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='customer/mainBranches'
              title={__('menuMainBranch', 'Principales')}
              icon='/media/icons/duotune/layouts/lay005.svg'
              hasBullet={false}
            />
          )}

          {(checkModuleOrScreen('', 'secondaryBranches') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='customer/secondaryBranches'
              title={__('menuSecondaryBranch', 'Sucursales')}
              icon='/media/icons/duotune/layouts/lay006.svg'
              hasBullet={false}
            />
          )}
        </>
      )}
      {(checkModuleOrScreen('security') ||
        checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-3'>
              <span className='menu-section text-success text-uppercase fs-9 ls-3'>
                {__('menuSecurity', 'Seguridad')}
              </span>
            </div>
          </div>

          {(checkModuleOrScreen('', 'users') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='security/users'
              title={__('menuUser', 'Administración de Usuarios')}
              icon='/media/icons/duotune/communication/com014.svg'
              hasBullet={false}
            />
          )}

          {(checkModuleOrScreen('', 'roles') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='security/roles'
              title={__('menuRoles', 'Roles')}
              icon='/media/icons/duotune/communication/com005.svg'
              hasBullet={false}
            />
          )}
        </>
      )}

      {checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-success text-uppercase fs-9 ls-3'>
                {__('menuSetting', 'Configuraciones')}
              </span>
            </div>
          </div>

          {(checkModuleOrScreen('', 'configs') ||
            checkRoleOrPermission(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`)) && (
            <AsideMenuItem
              to='setting/general'
              title='General'
              icon='/media/icons/duotune/coding/cod009.svg'
              hasBullet={false}
            />
          )}
        </>
      )}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
