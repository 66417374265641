import {useGet} from '@alejandro.devop/react-json-api-client'

export const useGetStatistics = (filters?: any) => {
  const [data, {loading, refresh, meta}]: any = useGet('statistics.statisticsByBranch', {
    filters: {...filters},
  })

  return {
    loading,
    refresh,
    meta,
    data: data?.data,
  }
}
