/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import useTranslate from 'app/hooks/useTranslate'
import {useGetStatistics} from 'app/services/StatisticService'
import _ from 'lodash'

type Props = {
  statistics?: any
  loadingStatistics?: boolean
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidget10: React.FC<Props> = ({
  statistics,
  loadingStatistics,
  className,
  chartColor,
  chartHeight,
}) => {
  const __ = useTranslate()
  const chartRef = React.useRef<HTMLDivElement | null>(null)

  const [totals, setTotals] = React.useState<any>([
    {name: 'Historial de Credito + Comportamiento', data: [0, 0, 0, 0, 0, 0]},
    {name: 'Consulta Score + Detalle', data: [0, 0, 0, 0, 0, 0]},
    {name: 'Localiza', data: [0, 0, 0, 0, 0, 0]},
  ])

  const [categories, setCategories] = React.useState<string[]>(['', '', '', '', '', ''])

  React.useMemo(() => {
    if (statistics) {
      const stats =
        JSON.parse(statistics[0]?.attributes?.statistics ?? JSON.stringify([])).consultByType ?? []
      const partials = _.map(stats, (item: any) => {
        return [
          {
            name: 'Historial de Credito + Comportamiento',
            data: _.filter(
              item,
              (data: any) => data.type === 'Historial de Credito + Comportamiento'
            ).map((data: any) => data.total),
          },
          {
            name: 'Consulta Score + Detalle',
            data: _.filter(item, (data: any) => data.type === 'Consulta Score + Detalle').map(
              (data: any) => data.total
            ),
          },
          {
            name: 'Localiza',
            data: _.filter(item, (data: any) => data.type === 'Localiza').map(
              (data: any) => data.total
            ),
          },
        ]
      })

      setTotals(
        partials.flat().reduce((acc: any, item: any) => {
          const existing = acc.find((group: any) => group.name === item.name)
          if (existing) {
            existing.data.push(...item.data)
          } else {
            acc.push({
              name: item.name,
              data: [...item.data],
            })
          }
          return acc
        }, [])
      )

      setCategories(_.map(stats, (item: any, key: string) => key))
    }
  }, [statistics])

  React.useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(totals, categories, chartColor, chartHeight)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [totals, categories, chartRef])

  return (
    <div className={`card ${className}`}>
      <div className='card-body py-5 d-flex justify-content-between flex-column overflow-hidden'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {__('consultsBySourceTitle', 'Consultas por Tipo')}
          </span>
          <span className='text-muted fw-bold fs-7 mx-3'>
            {__('consultByTypeDescription', 'Detalle Curva de Consultas por Tipo')}

            {loadingStatistics && <span className='ms-2 spinner-border spinner-border-sm'></span>}
          </span>
        </h3>
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
      </div>
    </div>
  )
}

const chartOptions = (
  data: any,
  categories: string[],
  chartColor: string,
  chartHeight: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const historyTotals = data.find(
    (item: any) => item.name === 'Historial de Credito + Comportamiento'
  )?.data
  const scoreTotals = data.find((item: any) => item.name === 'Consulta Score + Detalle')?.data
  const localizaTotals = data.find((item: any) => item.name === 'Localiza')?.data

  return {
    series: [
      {
        name: _.toUpper('Historial de Credito + Comportamiento'),
        data: historyTotals,
      },
      {
        name: _.toUpper('Consulta Score + Detalle'),
        data: scoreTotals,
      },
      {
        name: _.toUpper('Localiza'),
        data: localizaTotals,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export {MixedWidget10}
