import useFormatDateTime from 'app/hooks/useFormatDateTime'
import useTranslate from 'app/hooks/useTranslate'
import {FC} from 'react'

type Props = {
  title?: string
  comment?: string
  badge?: string
  date?: string
  period?: boolean
  withHours?: boolean
}

const ReusableCell: FC<Props> = ({
  title,
  comment,
  badge,
  date,
  period = false,
  withHours = false,
}) => {
  const __ = useTranslate()
  const formatDateTime = useFormatDateTime()

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        {title && !badge && <>{__(title + 'Title', title)}</>}
        {comment && <span>{comment}</span>}
        {badge && (
          <div className={`badge badge-light-${badge} fw-bolder`}>{__(title + 'Title', title)}</div>
        )}
        {date && (
          <div className='badge badge-light fw-bolder'>
            {date !== null ? formatDateTime(date, true, withHours, period) : 'Sin Datos'}
          </div>
        )}
      </div>
    </div>
  )
}

export {ReusableCell}
