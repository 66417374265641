import React, {FC, useEffect, useState} from 'react'
import {components} from 'react-select'

import {
  useGetClaimTypes,
  useGetClaimSubTypes,
  useGetObligationsByIdNumber,
} from 'app/services/ObligationService'

import {useGetObligationClaimWorkflowStates} from 'app/services/ObligationService'
import {DatePickerField, SingleSelect, TextArea} from 'app/modules/widgets/components/inputs'
import {useFormikContext, ErrorMessage, Field} from 'formik'
import useTranslate from 'app/hooks/useTranslate'
import {usePreviousValue} from 'app/helpers/usePreviousValue'
import useCheckRoleOrPermission from 'app/hooks/useCheckRoleOrPermission'
import {useGetMainBranches, useGetThirdPartiesByIdNumber} from 'app/services/CustomerService'
import {ErrorMessageField} from 'app/modules/utilities/alerts'

import _ from 'lodash'
import {
  useGetCities,
  useGetCountries,
  useGetGenders,
  useGetIdTypes,
  useGetPersonTypes,
  useGetStates,
} from 'app/services/SettingService'
import reduceThirdParty from 'app/helpers/ReduceThirdParty'
import ThirdPartyAddresses from './ThirdPartyAddresses'
import ThirdPartyTelephones from './ThirdPartyTelephones'
import ThirdPartyEmails from './ThirdPartyEmails'

type Props = {
  obligationClaim?: any
  isEditing?: boolean
}

const ClaimInformation: FC<Props> = ({isEditing, obligationClaim}) => {
  const __ = useTranslate()
  const isAllow = useCheckRoleOrPermission()

  const [idNumber, setIdNumber] = useState<string | null>(null)
  const [idNumberSCError, setIdNumberSCError] = useState<boolean>(false)
  const [idNumberONError, setIdNumberONError] = useState<boolean>(false)
  const [exist, setExist] = useState<boolean>(false)

  const {values, setFieldValue}: any = useFormikContext()

  const {data: countries, loading: loadingCountries} = useGetCountries()
  const {data: genders, loading: loadingGenders} = useGetGenders()

  const {data: thirdParty, loading: loadingThirdParty} = useGetThirdPartiesByIdNumber({
    id_number: idNumber,
  })

  const {
    data: obligations,
    refresh: refreshObligations,
    loading: loadingObligations,
  } = useGetObligationsByIdNumber({
    id_number: idNumber,
  })

  const {data: personTypes, loading: loadingPersonTypes} = useGetPersonTypes()
  const {
    data: idTypes,
    refresh: refreshIdTypes,
    loading: loadingIdTypes,
  } = useGetIdTypes({
    person_type_id:
      values.thirdParty.person_type_id?.value ||
      obligationClaim.attributes?.thirdParty.personType.id,
  })
  const {data: mainBranches, loading: loadingMainBranches} = useGetMainBranches({is_active: 1})
  const {data: claimTypes, loading: loadingClaimTypes} = useGetClaimTypes()
  const {
    data: claimSubTypes,
    refresh: refreshClaimSubTypes,
    loading: loadingClaimSubTypes,
  } = useGetClaimSubTypes({
    claim_type_id:
      values.claim_type_id?.value || obligationClaim.attributes?.claimSubType.claimType.id,
  })
  const {data: workflowStatuses, loading: loadingWorkflowStatuses} =
    useGetObligationClaimWorkflowStates()

  const previousValueClaimType = usePreviousValue(values.claim_type_id?.value)
  const previousValuePersonType = usePreviousValue(values.thirdParty.person_type_id?.value)
  const previousValueIdNumber = usePreviousValue(values.thirdParty.id_number?.value)

  const {
    data: birthDateStates,
    refresh: refreshBirthDateStates,
    loading: loadingBirthDateStates,
  } = useGetStates({country_id: values.thirdParty?.birthdateCountry?.value})
  const {
    data: birthDateCities,
    refresh: refreshBirthDateCities,
    loading: loadingBirthDateCities,
  } = useGetCities({
    country_id: values.thirdParty?.birthdateCountry?.value,
    state_id: values.thirdParty?.birthdateState?.value,
  })

  const previousValueBirthDateCountry = usePreviousValue(values.thirdParty?.birthdateCountry?.value)
  const previousValueBirthDateState = usePreviousValue(values.thirdParty?.birthdateState?.value)

  const {
    data: expeditionStates,
    refresh: refreshExpeditionStates,
    loading: loadingExpeditionStates,
  } = useGetStates({country_id: values.thirdParty?.expeditionCountry?.value})
  const {
    data: expeditionCities,
    refresh: refreshExpeditionCities,
    loading: loadingExpeditionCities,
  } = useGetCities({
    country_id: values.thirdParty?.expeditionCountry?.value,
    state_id: values.thirdParty?.expeditionState?.value,
  })

  const previousValueExpeditionCountry = usePreviousValue(
    values.thirdParty?.expeditionCountry?.value
  )
  const previousValueExpeditionState = usePreviousValue(values.thirdParty?.expeditionState?.value)

  const handleValidateUser = React.useCallback(() => {
    if (values.thirdParty.id_number === '') return true

    if (!values.thirdParty.id_type_id.only_number) {
      if (values.thirdParty.id_number.match(/^([a-zA-Z 0-9]*)$/) != null) {
        setIdNumberSCError(false)
        setIdNumberONError(false)
      } else {
        setIdNumberSCError(true)
        setIdNumberONError(false)
        return true
      }
    }

    if (values.thirdParty.id_type_id.only_number) {
      if (
        values.thirdParty.id_number.match(
          /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/
        ) != null
      ) {
        setIdNumberONError(false)
        setIdNumberSCError(false)
      } else {
        setIdNumberSCError(false)
        setIdNumberONError(true)
        return true
      }
    }

    setIdNumber(values.thirdParty.id_number)
  }, [values.thirdParty.id_number])

  const formatOptionLabel = ({label, commercial_name, name, badge}: any) => {
    return (
      <div className='d-flex align-items-center'>
        <div className='d-flex flex-column fs-7'>
          {label}
          <div className='text-muted text-gray-700 fs-9 mt-1'>
            {commercial_name}
            <span className={`badge badge-light-${badge} fs-9 mx-1 me-1`}>{name}</span>
          </div>
        </div>
      </div>
    )
  }

  const SingleValue = ({children, ...props}: any) => {
    return <components.SingleValue {...props}>{props.data.label}</components.SingleValue>
  }

  useEffect(() => {
    if (previousValueClaimType || values.claimType?.value !== previousValueClaimType) {
      refreshClaimSubTypes()
    }
  }, [values.claimType])

  useEffect(() => {
    if (previousValuePersonType || values.personType?.value !== previousValuePersonType) {
      refreshIdTypes()
    }
  }, [values.thirdParty.person_type_id])

  useEffect(() => {
    if (previousValueIdNumber || values.thirdParty.id_number !== previousValueIdNumber) {
      if (values.thirdParty.id_number !== '') {
        refreshObligations({filters: {id_number: values.thirdParty.id_number}})
      }
    }
  }, [values.thirdParty.id_type_id])

  useEffect(() => {
    if (null === thirdParty) {
      setFieldValue(`thirdParty.id_number`, idNumber)
      setFieldValue(`thirdParty.full_name`, '')
      setFieldValue(`thirdParty.commercial_name`, '')
      setFieldValue(`thirdParty.trading_name`, '')
      setFieldValue(`thirdParty.addresses`, [])
      setFieldValue(`thirdParty.telephones`, [])
      setFieldValue(`thirdParty.emails`, [])

      setExist(false)
      return
    }

    if (undefined !== thirdParty) {
      setFieldValue(`thirdParty`, reduceThirdParty([thirdParty.attributes])[0])
      setExist(true)
    }
  }, [thirdParty])

  useEffect(() => {
    if (
      previousValueBirthDateCountry ||
      values.thirdParty?.birthdateCountry?.value !== previousValueBirthDateCountry
    ) {
      refreshBirthDateStates()
    }

    if (!values.thirdParty?.birthdateCountry.id) return
  }, [values.thirdParty?.birthdateCountry])

  useEffect(() => {
    if (
      previousValueBirthDateState ||
      values.thirdParty?.birthdateState?.value !== previousValueBirthDateState
    ) {
      refreshBirthDateCities()
    }

    if (!values.thirdParty?.birthdateState.id) return
  }, [values.thirdParty?.birthdateState])

  useEffect(() => {
    if (
      previousValueExpeditionCountry ||
      values.thirdParty?.expeditionCountry?.value !== previousValueBirthDateCountry
    ) {
      refreshExpeditionStates()
    }

    if (!values.thirdParty?.expeditionCountry.id) return
  }, [values.thirdParty?.expeditionCountry])

  useEffect(() => {
    if (
      previousValueExpeditionState ||
      values.thirdParty?.expeditionState?.value !== previousValueExpeditionState
    ) {
      refreshExpeditionCities()
    }

    if (!values.thirdParty?.expeditionState.id) return
  }, [values.thirdParty?.expeditionState])

  return (
    <>
      <div className='row mb-6'>
        <div className='col-md-4'>
          <label className='required fs-6 form-label'>{__('personType', 'Tipo de Persona')}</label>
          <SingleSelect
            placeholder={
              loadingPersonTypes
                ? __('loadingTitle', 'Cargando') + '...'
                : __('selectTitle', 'Seleccionar')
            }
            isLoading={loadingPersonTypes}
            isDisabled={loadingPersonTypes}
            options={personTypes?.map(
              (i: {attributes: {name: string; need_holder_status: boolean}; id: any}) => ({
                label: i.attributes.name,
                value: i.id,
                need_holder_status: i.attributes.need_holder_status,
              })
            )}
            onChange={setFieldValue}
            className='basic-single'
            name={'thirdParty.person_type_id'}
            value={values.thirdParty.person_type_id}
            defaultValue={values.thirdParty.person_type_id}
          />

          <ErrorMessageField field={'thirdParty.person_type_id'} />

          <div className='text-muted text-gray-700 fs-9 mt-2'>
            {__(
              'personTypeIdCreateDescriptionTitle',
              'Seleccione el tipo de persona que desea crear, los tipos de documentos se cargaran dependiendo de esta selección.'
            )}
          </div>
        </div>

        <div className='col-lg-4'>
          <label className='form-label required'>{__('idType', 'Tipo de Documento')}</label>

          <SingleSelect
            placeholder={
              loadingIdTypes
                ? __('loadingTitle', 'Cargando') + '...'
                : __('selectTitle', 'Seleccionar')
            }
            isLoading={loadingIdTypes}
            isDisabled={loadingIdTypes}
            options={idTypes?.map(
              (i: {
                attributes: {name: string; need_verification: boolean; only_number: boolean}
                id: any
              }) => ({
                label: i.attributes.name,
                value: i.id,
                need_verification: i.attributes.need_verification,
                only_number: i.attributes.only_number,
              })
            )}
            onChange={setFieldValue}
            className='basic-single'
            name={'thirdParty.id_type_id'}
            value={values.thirdParty.id_type_id}
            defaultValue={values.thirdParty.id_type_id}
          />

          <ErrorMessageField field={'thirdParty.id_type_id'} />
        </div>

        <div className='col-lg-4'>
          <label className='form-label required'>{__('idNumber', 'Número Identificación')}</label>

          <div className='d-flex align-items-center'>
            <Field
              type='text'
              className='form-control'
              name={'thirdParty.id_number'}
              disabled={loadingThirdParty}
              onBlur={handleValidateUser}
              maxLength={12}
            />

            {loadingThirdParty && (
              <span className='m-2 spinner-border text-primary text-primary spinner-border-sm'></span>
            )}
          </div>

          <ErrorMessageField field={'thirdParty.id_number'} />

          {idNumberSCError && (
            <div className='text-danger mt-2'>
              <div className='fv-plugins-message-container'>
                <span role='alert'>
                  {__('specialCharError', 'Este campo no acepta caracteres especiales')}
                </span>
              </div>
            </div>
          )}

          {idNumberONError && (
            <div className='text-danger mt-2'>
              <div className='fv-plugins-message-container'>
                <span role='alert'>{__('idTypeNumericError', 'El valor debe ser numérico')}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      {!values.thirdParty.id_type_id?.need_verification && (
        <>
          <div className='row mb-4'>
            <div className='mt-4 mb-4'>
              <h2 className='fw-bold fs-7'>
                {__('expeditionInformationTitle', 'información sobre Expedición del documento')}
              </h2>
            </div>

            <div className='col-md-3'>
              <label className='fs-6 fw-bold form-label mb-2'>
                {__('expeditionCountryTitle', 'Pais de Expedición')}
              </label>

              <SingleSelect
                placeholder={
                  loadingCountries
                    ? __('loadingTitle', 'Cargando') + '...'
                    : __('selectTitle', 'Seleccionar')
                }
                isLoading={loadingCountries}
                isDisabled={loadingCountries}
                options={countries?.map((i: {attributes: {name: string}; id: any}) => ({
                  label: i.attributes.name,
                  value: i.id,
                }))}
                onChange={setFieldValue}
                className='basic-single'
                name={'thirdParty.expeditionCountry'}
                defaultValue={values.thirdParty.expeditionCountry}
                value={values.thirdParty.expeditionCountry}
              />
            </div>

            <div className='col-md-3'>
              <label className='fs-6 fw-bold form-label mb-2'>
                {__('expeditionStateTitle', 'Depto de Expedición')}
              </label>

              <SingleSelect
                placeholder={
                  loadingExpeditionStates
                    ? __('loadingTitle', 'Cargando') + '...'
                    : __('selectTitle', 'Seleccionar')
                }
                isLoading={loadingExpeditionStates}
                isDisabled={loadingExpeditionStates}
                options={expeditionStates?.map((i: {attributes: {name: string}; id: any}) => ({
                  label: i.attributes.name,
                  value: i.id,
                }))}
                onChange={setFieldValue}
                className='basic-single'
                name={'thirdParty.expeditionState'}
                defaultValue={values.thirdParty.expeditionState}
                value={values.thirdParty.expeditionState}
              />
            </div>

            <div className='col-md-3'>
              <label className='required fs-6 fw-bold form-label mb-2'>
                {__('expeditionCityTitle', 'Ciudad de Expedición')}
              </label>

              <SingleSelect
                placeholder={
                  loadingExpeditionCities
                    ? __('loadingTitle', 'Cargando') + '...'
                    : __('selectTitle', 'Seleccionar')
                }
                isLoading={loadingExpeditionCities}
                isDisabled={loadingExpeditionCities}
                options={expeditionCities?.map((i: {attributes: {name: string}; id: any}) => ({
                  label: i.attributes.name,
                  value: i.id,
                }))}
                onChange={setFieldValue}
                className='basic-single'
                name={'thirdParty.expedition_city_id'}
                defaultValue={values.thirdParty.expedition_city_id}
                value={values.thirdParty.expedition_city_id}
              />

              <ErrorMessageField field={'thirdParty.expedition_city_id'} />
            </div>

            <div className='col-md-3'>
              <label className='form-label required'>
                {__('expeditionAtTitle', 'Fecha de Expedición')}
              </label>
              <div>
                <DatePickerField name='thirdParty.expedition_at' className='form-control' />
              </div>

              <ErrorMessageField field={'thirdParty.expedition_at'} />
            </div>
          </div>

          <div className='row mb-6'>
            <div className='col-md-6'>
              <label className='required fs-6 fw-bold form-label'>
                {__('fullNameTitle', 'Nombre Completo')}
              </label>
              <Field
                type='text'
                className='form-control'
                name={'thirdParty.full_name'}
                value={values.thirdParty.full_name}
              />

              <ErrorMessageField field={'thirdParty.full_name'} />
            </div>
            <div className='col-md-6'>
              <label className='form-label mb-3 required'>{__('gender', 'Genero')}</label>

              <SingleSelect
                placeholder={
                  loadingGenders
                    ? __('loadingTitle', 'Cargando') + '...'
                    : __('selectTitle', 'Seleccionar')
                }
                isLoading={loadingGenders}
                isDisabled={loadingGenders}
                value={values.thirdParty.gender_id}
                options={genders?.map((i: {attributes: {name: string}; id: any}) => ({
                  label: i.attributes.name,
                  value: i.id,
                }))}
                onChange={setFieldValue}
                className='basic-single'
                name='thirdParty.gender_id'
              />

              <ErrorMessageField field={'gender_id'} />
            </div>
          </div>

          <div className='row mb-4'>
            <div className='mt-4 mb-4'>
              <h2 className='fw-bold fs-7'>
                {__('birthDataInformationTitle', 'información sobre lugar de nacimiento')}
              </h2>
            </div>

            <div className='col-md-3'>
              <label className='fs-6 fw-bold form-label mb-2'>
                {__('birthDateCountryTitle', 'Pais de Nacimiento')}
              </label>

              <SingleSelect
                placeholder={
                  loadingCountries
                    ? __('loadingTitle', 'Cargando') + '...'
                    : __('selectTitle', 'Seleccionar')
                }
                isLoading={loadingCountries}
                isDisabled={loadingCountries}
                options={countries?.map((i: {attributes: {name: string}; id: any}) => ({
                  label: i.attributes.name,
                  value: i.id,
                }))}
                onChange={setFieldValue}
                className='basic-single'
                name={'thirdParty.birthdateCountry'}
                defaultValue={values.thirdParty.birthdateCountry}
                value={values.thirdParty.birthdateCountry}
              />
            </div>

            <div className='col-md-3'>
              <label className='fs-6 fw-bold form-label mb-2'>
                {__('stateBirthDateTitle', 'Depto de Nacimiento')}
              </label>

              <SingleSelect
                placeholder={
                  loadingBirthDateStates
                    ? __('loadingTitle', 'Cargando') + '...'
                    : __('selectTitle', 'Seleccionar')
                }
                isLoading={loadingBirthDateStates}
                isDisabled={loadingBirthDateStates}
                options={birthDateStates?.map((i: {attributes: {name: string}; id: any}) => ({
                  label: i.attributes.name,
                  value: i.id,
                }))}
                onChange={setFieldValue}
                className='basic-single'
                name={'thirdParty.birthdateState'}
                defaultValue={values.thirdParty.birthdateState}
                value={values.thirdParty.birthdateState}
              />
            </div>

            <div className='col-md-3'>
              <label className='required fs-6 fw-bold form-label mb-2'>
                {__('cityBithDateTitle', 'Ciudad de Nacimiento')}
              </label>

              <SingleSelect
                placeholder={
                  loadingBirthDateCities
                    ? __('loadingTitle', 'Cargando') + '...'
                    : __('selectTitle', 'Seleccionar')
                }
                isLoading={loadingBirthDateCities}
                isDisabled={loadingBirthDateCities}
                options={birthDateCities?.map((i: {attributes: {name: string}; id: any}) => ({
                  label: i.attributes.name,
                  value: i.id,
                }))}
                onChange={setFieldValue}
                className='basic-single'
                name={'thirdParty.birthdate_city_id'}
                defaultValue={values.thirdParty.birthdate_city_id}
                value={values.thirdParty.birthdate_city_id}
              />

              <ErrorMessageField field={'thirdParty.birthdate_city_id'} />
            </div>

            <div className='col-md-3'>
              <label className='form-label required'>
                {__('birthDateAtTitle', 'Fecha de Nacimiento')}
              </label>
              <div>
                <DatePickerField name='thirdParty.birthdate_at' className='form-control' />
              </div>

              <ErrorMessageField field={'thirdParty.birthdate_at'} />
            </div>
          </div>
        </>
      )}

      {values.thirdParty.id_type_id?.need_verification && (
        <>
          <div className='row mb-6'>
            <div className='col-md-6'>
              <label className='fs-6 required fw-bold form-label'>
                {__('tradingName', 'Razón Social')}
              </label>
              <Field
                type='text'
                className='form-control'
                name={'thirdParty.trading_name'}
                value={values.thirdParty.trading_name}
              />

              <ErrorMessageField field={'thirdParty.trading_name'} />
            </div>
            <div className='col-md-6'>
              <label className='fs-6 fw-bold form-label '>
                {__('commecialName', 'Nombre Comercial')}
              </label>
              <Field
                type='text'
                className='form-control'
                name={'thirdParty.commercial_name'}
                value={values.thirdParty.commercial_name}
              />

              <ErrorMessageField field={'thirdParty.commercial_name'} />
            </div>
          </div>
        </>
      )}

      {idNumber && !isEditing && !exist && !loadingThirdParty && (
        <>
          <div className='row mb-6'>
            <ThirdPartyAddresses k={0} />
          </div>

          <div className='row mb-6'>
            <ThirdPartyTelephones k={0} />
          </div>

          <div className='row mb-6'>
            <ThirdPartyEmails k={0} />
          </div>
        </>
      )}

      <div className='row mb-6'>
        <div className='col-md-12 '>
          <label className='form-label'>{__('obligationsTitle', 'Obligaciones')}</label>
          <SingleSelect
            placeholder={loadingObligations ? 'Cargando...' : 'Seleccionar'}
            isLoading={loadingObligations}
            isDisabled={loadingObligations}
            value={values.obligation_id}
            options={obligations?.map(
              (i: {
                id: string
                obligation_number: string
                name: string
                commercial_name: string
                badge: string
              }) => ({
                label: i.obligation_number,
                value: i.id,
                name: i.name,
                commercial_name: i.commercial_name,
                badge: i.badge,
              })
            )}
            formatOptionLabel={formatOptionLabel}
            getOptionLabel={(option) => option.label}
            components={{SingleValue}}
            isMulti={true}
            onChange={setFieldValue}
            className='basic-single'
            name='obligation_id'
          />

          <ErrorMessageField field={'obligation_id'} />

          <div className='text-muted text-gray-700 fs-9 mt-2'>
            {__(
              'obligationCreateClaimDescriptionTitle',
              'Seleccione las obligaciones que desea relacionar al reclamo. Si no encuentra la obligación que desea relacionar, puede crearla desde el módulo de obligaciones.'
            )}
          </div>
        </div>
      </div>

      {isAllow(`${process.env.REACT_APP_SUPER_ADMIN_ROL}`) && (
        <div className='row mb-6'>
          <div className='col-md-12'>
            <label className='form-label'>{__('mainBranchTitle', 'Principal')}</label>

            <SingleSelect
              placeholder={
                loadingMainBranches
                  ? __('loadingTitle', 'Cargando') + '...'
                  : __('selectTitle', 'Seleccionar')
              }
              isLoading={loadingMainBranches}
              isDisabled={loadingMainBranches}
              value={values.main_branch_id}
              options={mainBranches?.map((i: {id: any; attributes: {commercial_name: string}}) => ({
                label: i.attributes.commercial_name,
                value: i.id,
              }))}
              onChange={setFieldValue}
              className='basic-single'
              name='main_branch_id'
            />
            <ErrorMessageField field={'main_branch_id'} />

            <div className='text-muted text-gray-700 fs-9 mt-2'>
              {__(
                'mainBranchClailTitle',
                'Seleccione la principa a la cual va a asociar el reclamo. '
              )}
            </div>
          </div>
        </div>
      )}

      <div className='row mb-6'>
        <div className='col-lg-6'>
          <label className='form-label required'>{__('claimTypeTitle', 'Tipo Reclamo')}</label>
          <SingleSelect
            placeholder={loadingClaimTypes ? 'Cargando...' : 'Seleccionar'}
            isLoading={loadingClaimTypes}
            value={values.claim_type_id}
            defaultValue={values.claim_type_id}
            options={claimTypes?.map((i: {id: any; attributes: {name: string}}) => ({
              label: i.attributes.name,
              value: i.id,
            }))}
            onChange={setFieldValue}
            className='basic-single'
            isDisabled={loadingClaimTypes}
            name='claim_type_id'
          />

          <div className='text-danger mt-2'>
            <ErrorMessage name='claim_type_id' />
          </div>
        </div>

        <div className='col-lg-6'>
          <label className='form-label required'>
            {__('claimSubTypeTitle', 'Sub Tipo Reclamo')}
          </label>

          <SingleSelect
            placeholder={loadingClaimSubTypes ? 'Cargando...' : 'Seleccionar'}
            isLoading={loadingClaimSubTypes}
            value={values.claim_sub_type_id}
            defaultValue={values.claim_sub_type_id}
            options={claimSubTypes?.map((i: {id: any; attributes: {name: string}}) => ({
              label: i.attributes.name,
              value: i.id,
            }))}
            onChange={setFieldValue}
            className='basic-single'
            isDisabled={loadingClaimSubTypes}
            name='claim_sub_type_id'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='claim_sub_type_id' />
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <div className='col-lg-12'>
          <label className='form-label required'>{__('descriptionTitle', 'Descripción')}</label>

          <TextArea
            name='description'
            rows='6'
            placeholder={__(
              'claimClosedDescription',
              'Ingrese de forma detalla la descripción de la reclamación'
            )}
          />

          <div className='text-danger mt-2'>
            <ErrorMessage name='commercial_name' />
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <div className='col-md-6'>
          <label className='form-check-label form-label'>
            {__('statusTitle', 'Estado del Reclamo')}
          </label>
          <SingleSelect
            placeholder={loadingWorkflowStatuses ? 'Cargando...' : 'Seleccionar'}
            isLoading={loadingWorkflowStatuses}
            defaultValue={
              obligationClaim?.attributes?.actualStepName
                ? {
                    label: obligationClaim?.attributes.actualStepName?.text ?? '',
                    value: obligationClaim?.attributes.actualStepName?.key ?? '',
                  }
                : ''
            }
            options={workflowStatuses?.attributes.map((i: {text: string; key: any}) => ({
              label: i.text,
              value: i.key,
            }))}
            onChange={setFieldValue}
            className='basic-single'
            isDisabled={loadingWorkflowStatuses}
            name='workflow_status_id'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='workflow_status_id' />
          </div>
        </div>
      </div>
    </>
  )
}

export {ClaimInformation}
