import _ from 'lodash'
import format from 'date-fns/format'

const reduceFormValue = (obj: any, edit: any = false) => {
  let newValues = obj

  if (!edit) {
    newValues = removeNulls(obj)
  }

  newValues = _.forEach(newValues, function (o: any, i: string) {
    if (_.isObject(o)) {
      if (_.has(o, 'label')) {
        let value = _.get(o, 'value')
        newValues[i] =
          // @ts-ignore
          (typeof value === 'string' || value instanceof String) && !isNaN(value)
            ? // @ts-ignore
              parseInt(value)
            : value
        return true
      }

      if (_.isArray(o) && !_.isObject(o[0])) {
        newValues[i] = o.toString()
        return true
      }

      return (newValues[i] = reduceFormValue(o))
    }
  })

  return newValues
}

function removeNulls(data: any) {
  return Object.entries(data).reduce((r: any, [k, v]) => {
    if (v !== '' && v !== null) {
      if (_.isDate(v)) {
        r[k] = format(v, 'yyyy-MM-dd')
      } else if (typeof v === 'object') {
        const child = removeNulls(v)
        if (Object.keys(child).length) {
          r[k] = child
        }
      } else {
        r[k] = v
      }
    }

    return r
  }, {})
}

export default reduceFormValue
