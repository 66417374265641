import {useSession} from '@alejandro.devop/redux-persistance'
import useTranslate from 'app/hooks/useTranslate'
import JSZip from 'jszip'
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import uuid from 'react-uuid'
import _ from 'lodash'

type FetchState = 'idle' | 'pending' | 'success' | 'failed'

export interface UploadManager {
  upload: (files: File) => void
  addRefreshCallback: (cb: () => void) => void
  removeRefreshCallback: (cb: () => void) => void
  compressing: boolean
  slicing: boolean
  loading: boolean
  progressSlice: number
  selectedFile: Array<File | Blob | null>
  selectedFilePath: Array<any>
  selectedFileName: String
  error: String
  setError: any
}

export interface UploadFile {
  id: number
  name: string
  status: FetchState
  loaded: number
  total: number
}

const UploadContext = createContext<UploadManager>({
  upload: () => {
    throw Error('UploadContext has no Provider!')
  },
  addRefreshCallback: () => {
    throw Error('UploadContext has no Provider!')
  },
  removeRefreshCallback: () => {
    throw Error('UploadContext has no Provider!')
  },
  compressing: false,
  slicing: false,
  loading: false,
  progressSlice: 0,
  selectedFile: [],
  selectedFilePath: [],
  selectedFileName: '',
  error: '',
  setError: () => {},
})

const UploadFilesContext = createContext<UploadFile[]>([])

export const useUpload = (): UploadManager => useContext(UploadContext)
export const useUploadFiles = (): UploadFile[] => useContext(UploadFilesContext)

interface UploadContextWrapperProps {
  children: JSX.Element | JSX.Element[] | any
}

export function UploadContextWrapper({children}: UploadContextWrapperProps): JSX.Element {
  const __ = useTranslate()

  const {store = {}} = useSession()
  const pattern = /^[a-zA-ZáéíñóúüÁÉÑÓÚÜ0-9&_\@\#\.\,\ \-\/\\(\)\�\\\\+\\]+$/
  const chunkSize = 10000000
  const API_URL: any = process.env.REACT_APP_API_URL

  const [files, setFiles] = useState<UploadFile[]>([])

  const [selectedFile, setSelectedFile] = useState<Array<File | Blob>>([])
  const [selectedFilePath, setSelectedFilePath] = useState<Array<any>>([])
  const [selectedFileName, setSelectedFileName] = useState<string>('')

  const [slicing, setSlicing] = useState<boolean>(false)
  const [progressSlice, setProgressSlice] = useState<number>(0)

  const [refreshCallbacks, setRefreshCallbacks] = useState<Array<() => void>>([])
  const [needsRefreshing, setNeedsRefreshing] = useState<boolean>(false)
  const [compressing, setCompressing] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const sendFileToServer = async (fileName: string, is_sliced: boolean = false) => {
    const {token} = store
    setLoading(true)

    const zip = new JSZip()
    _.forEach(selectedFile, (file, index) => {
      zip.file(selectedFilePath[index], file, {compression: 'DEFLATE'})
    })

    const content = await zip.generateAsync({type: 'blob'})

    const fd = new FormData()
    fd.append('source', content, `${fileName}.zip`)
    fd.append('config_id', '1')

    fd.append('is_sliced', `${is_sliced}`)

    const response = await fetch(`${API_URL}/api/v1/obligation/obligationControls/batch`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'post',
      body: fd,
    })

    const jsonResponse = await response.json()

    if (jsonResponse?.errors) {
      setError(jsonResponse?.errors[0].detail)
    }

    localStorage.setItem('firstPosition', '')
    localStorage.setItem('lastPosition', '')

    setLoading(false)
  }

  const updateFileFactory = (id: number) => (getUpdated: (oldFile: UploadFile) => UploadFile) => {
    setFiles((oldFiles) => {
      const oldFile = oldFiles.find((f) => f.id === id)
      if (oldFile) {
        return oldFiles
          .filter((f) => f.id !== id)
          .concat([getUpdated(oldFile)])
          .sort((a, b) => b.id - a.id)
      }
      return oldFiles
    })
  }

  const validatedContentFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (file: any) => {
        let newContent = file.currentTarget.result.split('\n')
        let firstPosition = newContent.shift()
        let lastPosition = newContent.pop()

        if (firstPosition !== '' && firstPosition.includes('|')) {
          setError(__('errorFormatTypeTitle', 'El archivo no tiene el formato permitido.'))
          reject(true)
        }

        resolve(true)
      }

      reader.onerror = () => {
        reject(reader.error)
      }

      reader.readAsText(file)
    })
  }

  const readFileAsText = (chunk: any, onlyOne: boolean) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (chunk: any) => {
        let newContent = chunk.currentTarget.result
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .split('\n')
        let firstPosition = newContent.shift()
        let lastPosition = newContent.pop()

        if (firstPosition !== '' && firstPosition.includes('|')) {
          setError(__('errorFormatTypeTitle', 'El archivo no tiene el formato permitido.'))
          reject(true)
        }

        if (localStorage.getItem('firstPosition') || localStorage.getItem('lastPosition')) {
          if (firstPosition.length < 801) {
            newContent = [localStorage.getItem('lastPosition') + firstPosition, ...newContent]
          }
        }

        if (firstPosition.includes('HHHHHH')) {
          newContent = [firstPosition, ...newContent]
          localStorage.setItem('lastPosition', lastPosition)

          if (onlyOne) {
            newContent = [...newContent, lastPosition]
          }

          resolve(newContent)
          return
        }

        if (lastPosition.length === 801 || lastPosition.includes('ZZZZZZ')) {
          newContent = [...newContent, lastPosition]
          resolve(newContent)
          return
        }

        // If string is less that settting lenght (801), save the text to add in the next slice
        if (firstPosition.length < 801) {
          localStorage.setItem('firstPosition', firstPosition)
        }

        // If string is less that settting lenght (801), save the text to add in the next slice
        if (lastPosition.length < 801) {
          localStorage.setItem('lastPosition', lastPosition)
        }

        resolve(newContent)
      }

      reader.onerror = () => {
        reject(reader.error)
      }

      reader.readAsText(chunk, 'iso 8859-2')
    })
  }

  const sliceFile = useCallback(
    async (file: File, fileName: string) => {
      try {
        if (!slicing) {
          setSlicing(true)
          const [fileName] = file.name.split('.')
          let uuid_file = uuid()
          let onlyOneFile = chunkSize >= file.size

          for (let start = 0; start < file.size; start += chunkSize) {
            const chunk = file.slice(start, start + chunkSize)

            let newContent: any = await readFileAsText(chunk, onlyOneFile)

            let name = `${start}_${fileName}_${uuid_file}`

            const zip = new JSZip()
            let textContent = new Blob(newContent, {type: 'text/plain;charset=ISO 8859-1'})
            zip.file(`${name}.txt`, textContent, {compression: 'DEFLATE'})
            const content = await zip.generateAsync({type: 'blob'})

            setProgressSlice((start / file.size) * 100)

            selectedFile.push(content)
            selectedFilePath.push(`${name}.zip`)
          }

          setSlicing(false)
          sendFileToServer(fileName, true)
        }
      } catch (err: any) {
        setSlicing(false)
      }
    },
    [slicing]
  )

  const compressFile = useCallback(
    async (file: File, fileName: string) => {
      try {
        if (!compressing) {
          let format: any = await validatedContentFile(file)
          const [fileName] = file.name.split('.')
          let uuid_file = uuid()
          setProgressSlice(100)

          setCompressing(true)

          const zip = new JSZip()
          zip.file(`${fileName}_${uuid_file}.txt`, file, {compression: 'DEFLATE'})
          const content = await zip.generateAsync({type: 'blob'})

          selectedFile.push(content)

          selectedFilePath.push(`${fileName}_${uuid_file}.zip`)
          setCompressing(false)
          sendFileToServer(fileName, false)
        }
      } catch (err: any) {
        setCompressing(false)
      }
    },
    [compressing]
  )

  const upload = useCallback(
    async (file: File) => {
      const {size, type, name} = file || {}
      const [fileName] = name.split('.')
      const sizeInMb = size / (1024 * 1024)

      if (type !== 'text/plain') {
        setError(__('errorFileTypeTitle', 'El archivo no es un archivo válido, debe ser un .txt'))
        return false
      }

      setSelectedFileName(fileName)

      if (sizeInMb >= 500) {
        sliceFile(file, fileName)
      } else {
        compressFile(file, fileName)
      }
    },
    [selectedFile, store, selectedFilePath]
  )

  const uploadManager: UploadManager = useMemo(
    () => ({
      upload,
      addRefreshCallback: (cb) => {
        setRefreshCallbacks((oldCbs) => [...oldCbs, cb])
      },
      removeRefreshCallback: (cb) => {
        setRefreshCallbacks((oldCbs) => oldCbs.filter((oldCb) => oldCb !== cb))
      },
      compressing: compressing,
      slicing: slicing,
      progressSlice: progressSlice,
      loading: loading,
      selectedFile: selectedFile,
      selectedFilePath: selectedFilePath,
      selectedFileName: selectedFileName,
      error: error,
      setError: setError,
    }),
    [upload, selectedFile, compressing, slicing, error, progressSlice, loading]
  )

  useEffect(() => {
    if (needsRefreshing) {
      refreshCallbacks.forEach((cb) => cb())
      setNeedsRefreshing(false)
    }
  }, [needsRefreshing, refreshCallbacks])

  return <UploadContext.Provider value={uploadManager}>{children}</UploadContext.Provider>
}
