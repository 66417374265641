import * as Yup from 'yup'

export const Schema = [
  Yup.object().shape({
    thirdParty: Yup.object().shape({
      id_type_id: Yup.object().required('Requerido').nullable(),
      id_number: Yup.string().when('id_type_id', {
        is: (id_type_id: any) => {
          return id_type_id?.only_number ?? false
        },
        then: Yup.string()
          .required('Requerido')
          .matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, 'idTypeNumericError')
          .nullable(),
        otherwise: Yup.string()
          .required('Requerido')
          .matches(/^([a-zA-Z 0-9]*)$/, 'specialCharError')
          .nullable(),
      }),
      full_name: Yup.string()
        .required('Requerido')
        .matches(/^([a-zA-ZáéíñóúüÁÉÑÓÚÜ -]*)$/, 'specialCharError')
        .nullable(),
    }),
    claim_type_id: Yup.object().required('Requerido').nullable(),
    claim_sub_type_id: Yup.object().required('Requerido').nullable(),
    workflow_status_id: Yup.object().required('Requerido').nullable(),
    description: Yup.string().required('Requerido').nullable(),
  }),
]
