import {FC} from 'react'
import {Field, useFormikContext} from 'formik'

import useTranslate from 'app/hooks/useTranslate'
import {ErrorMessageField} from 'app/modules/utilities/alerts'

type Props = {
  k: number
}

const ThirdPartyEmails: FC<Props> = ({k}) => {
  const __ = useTranslate()

  const {values}: any = useFormikContext()

  return (
    <div key={k} className='border'>
      <div className='d-flex justify-content-center mb-6 mt-6'>
        <h2 className='fw-bold fs-6'>{__('emailTitle', 'Correo Electrónico')}</h2>
      </div>

      <div className='col-md-12 fv-row'>
        <div className='row mb-10'>
          <div className='fv-row'>
            <label className='form-label'>{__('emailTitle', 'Correo Electronico')}</label>
            <Field
              type='text'
              className='form-control'
              placeholder={__('emailTitle', 'Correo Electronico')}
              name={'thirdParty.emails[' + k + '].email'}
              value={values.thirdParty.emails[k]?.email}
            />

            <ErrorMessageField field={'thirdParty.emails[' + k + '].email'} />
          </div>
        </div>
      </div>
      <div className='separator mb-4'></div>
    </div>
  )
}

export default ThirdPartyEmails
