import {IObligationClaim} from './_models'

export const inits: IObligationClaim = {
  claim_sub_type_id: '',
  claim_type_id: '',
  obligation_id: '',
  description: '',
  workflow_status_id: '',
  main_branch_id: '',
  secondary_branch_id: '',
  thirdParty: {
    person_type_id: '',
    id_type_id: '',
    verification_digit: '',
    id_number: '',
    trading_name: '',
    commercial_name: '',
    full_name: '',

    birthdate_at: '',
    birthdateCountry: '',
    birthdateState: '',
    birthdate_city_id: '',
    expedition_at: '',
    expeditionCountry: '',
    expeditionState: '',
    expedition_city_id: '',
    gender_id: '',

    addresses: [
      {
        id: 0,
        address: '',
        additional: '',
        country: '',
        state: '',
        city_id: '',
      },
    ],
    telephones: [
      {
        id: 0,
        telephone_type_id: '',
        number: '',
        country: '',
        state: '',
        city_id: '',
        need_city: false,
      },
    ],
    emails: [
      {
        id: 0,
        email: '',
      },
    ],
  },
}
