/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {MixedWidget2, MixedWidget10, MixedWidget11} from '../../../_metronic/partials/widgets'
import {useGetStatistics} from 'app/services/StatisticService'

const DashboardPage: FC = () => {
  const {data: statistics, loading: loadingStatistics} = useGetStatistics()

  return (
    <>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4 col-md-12 col-sm-12 col-lg-4'>
          <MixedWidget2
            statistics={statistics}
            loadingStatistics={loadingStatistics}
            className='card-xl-stretch mb-xl-8'
            chartColor='default'
            chartHeight='200px'
            strokeColor='#04cb7b'
          />
        </div>

        <div className='col-xxl-8 col-md-12 col-sm-12 col-lg-8'>
          <MixedWidget11
            statistics={statistics}
            loadingStatistics={loadingStatistics}
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='280px'
          />
          <MixedWidget10
            statistics={statistics}
            loadingStatistics={loadingStatistics}
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='280px'
          />
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]} description='Estadísticas'>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
