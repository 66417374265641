/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../helpers'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import useTranslate from 'app/hooks/useTranslate'
import _ from 'lodash'

type Props = {
  statistics?: any
  loadingStatistics?: boolean
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
}

const MixedWidget2: React.FC<Props> = ({
  statistics,
  loadingStatistics,
  className,
  chartColor,
  chartHeight,
  strokeColor,
}) => {
  const chartRef = React.useRef<HTMLDivElement | null>(null)
  const __ = useTranslate()

  const [totals, setTotals] = React.useState<number[]>([0, 0, 0, 0, 0, 0])
  const [months, setMonths] = React.useState<string[]>(['', '', '', '', '', ''])

  React.useMemo(() => {
    if (statistics) {
      const stats =
        JSON.parse(statistics[0]?.attributes?.statistics ?? JSON.stringify([])).consultByMonth ?? []
      setTotals(_.map(stats, (item: any) => item.total))
      setMonths(_.map(stats, (item: any) => item.month))
    }
  }, [statistics])

  React.useEffect(() => {
    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(totals, months, chartHeight, chartColor, strokeColor)
    )

    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [totals, months])

  return (
    <div className={`card ${className}`}>
      <div className={`card-header border-0 py-5 bg-${chartColor}`}>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>
            {__('consultsByMonthTitle', 'Consultas Por Mes')}
          </span>

          <span className='text-muted fw-bold fs-7'>
            {__('consultsByMonthDescription', 'Detalle Curva de Consultas')}

            {loadingStatistics && <span className='ms-2 spinner-border spinner-border-sm'></span>}
          </span>
        </h3>
      </div>

      <div className='card-body p-0'>
        <div
          ref={chartRef}
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
        ></div>

        <div className='card-p mt-n10 position-relative'>
          <div className='row g-0'>
            <div className='col bg-light-success px-6 py-8 rounded-2 me-7 mb-7'>
              <KTSVG
                path='/media/icons/duotune/files/fil023.svg'
                className='svg-icon-3x svg-icon-success d-block my-2'
              />
              <a href='/obligations/batch' className='text-success fw-bold fs-6 mt-2'>
                {__('menuBatchReport', 'Reporte en Lotes')}
              </a>
            </div>

            <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
              <KTSVG
                path='/media/icons/duotune/communication/com014.svg'
                className='svg-icon-3x svg-icon-primary d-block my-2'
              />
              <a href='/customer/thirdParties' className='text-primary fw-bold fs-6'>
                {__('menuThirdParty', 'Terceros')}
              </a>
            </div>
          </div>
          <div className='row g-0'>
            <div className='col bg-light-info px-6 py-8 rounded-2 me-7'>
              <KTSVG
                path='/media/icons/duotune/graphs/gra010.svg'
                className='svg-icon-3x svg-icon-info d-block my-2'
              />
              <a href='/reports' className='text-info fw-bold fs-6 mt-2'>
                {__('menuReportZone', 'Zona Reportes')}
              </a>
            </div>

            <div className='col bg-light-dark px-6 py-8 rounded-2'>
              <KTSVG
                path='/media/icons/duotune/files/fil001.svg'
                className='svg-icon-3x svg-icon-dark d-block my-2'
              />
              <a href='/obligations/lists' className='text-dark fw-bold fs-6'>
                {__('menuObligations', 'Obligaciones')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const chartOptions = (
  data: number[],
  dataCategories: string[],
  chartHeight: string,
  chartColor: string,
  strokeColor: string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const lightColor = getCSSVariableValue('--bs-light-info')

  return {
    series: [
      {
        name: 'Consultas',
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: dataCategories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

export {MixedWidget2}
