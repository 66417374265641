import React, {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import * as Yup from 'yup'

import {useFormik, FormikHelpers} from 'formik'
// @ts-ignore
import {useSession} from '@alejandro.devop/redux-persistance'
import useTranslate from 'app/hooks/useTranslate'

interface LoginFormProps {
  loading: boolean
  onSubmit: (values: any, options: FormikHelpers<any>) => Promise<void>
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('signInEmailWrongFormatError').required('signInEmailRequiredError'),
  password: Yup.string()
    .min(8, 'changePasswordPasswordErrorMinChrsDescription')
    .required('changePasswordConfirmPasswordRequireField'),
})

const initialValues = {
  email: '',
  password: '',
}

const LoginForm: FC<LoginFormProps> = ({loading, onSubmit}) => {
  const {store = {}} = useSession()
  const [textType, setTextType] = useState<string>('password')
  const __ = useTranslate()

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
      <form
        className='form w-100 py-5 py-lg-5'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>{__('singInTitle', 'Acceso Plataforma PROCRÉDITO')}</h1>

          <div className='alert alert-secondary d-flex align-items-center p-5 mb-10'>
            <div className='d-flex flex-column'>
              <span
                /* @ts-ignore */
                style={{textAlign: 'justify', textJustify: 'inter-word'}}
              >
                {__(
                  'singInDescription',
                  'Este acceso es para empresarios PROCRÉDITO. Para iniciar sesión ingresa tu correo electrónico y contraseña.'
                )}
              </span>
            </div>
          </div>
        </div>

        {store?.user?.isRestorePassword ? (
          <div className='mb-lg-15 alert alert-info'>
            <div
              className='alert-text font-weight-bold'
              /* @ts-ignore */
              style={{textAlign: 'justify', textJustify: 'inter-word'}}
            >
              {__(
                'singInSendTemporalEmailTitle',
                'En PROCRÉDITO nos preocupa su información, por eso, para acceder a la plataforma hemos enviado una contraseña temporal a su correo electrónico'
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        {store?.user?.restoredPassword ? (
          <div className='mb-lg-15 alert alert-primary'>
            <div
              className='alert-text font-weight-bold'
              /* @ts-ignore */
              style={{textAlign: 'justify', textJustify: 'inter-word'}}
            >
              {__(
                'singInChangedPassword',
                'Hemos cambiado la contraseña, ahora puede acceder a la plataforma con su nueva contraseña, esta expira cada 2 meses por su seguridad'
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        {store?.isExpiredUserToken ? (
          <div className='mb-lg-15 alert alert-info'>
            <div
              className='alert-text font-weight-bold'
              /* @ts-ignore */
              style={{textAlign: 'justify', textJustify: 'inter-word'}}
            >
              {__(
                'singInExpiredUserTokenTitle',
                'En PROCRÉDITO nos preocupamos por su Seguridad, hemos notado que no está utilizando la plataforma, por eso cerramos la sesión automáticamente. Para volver a ingresar, inicie sesión.'
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold text-align-center'>{formik.status}</div>
          </div>
        ) : (
          ''
        )}

        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>
            {__('singInEmailLabel', 'Correo Electrónico')}
          </label>
          <input
            placeholder='Usuario'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>
                {__(formik.errors.email, 'El correo electronico no cumple con el formato')}
              </span>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                {__('singInPasswordLabel', 'Contraseña')}
              </label>
            </div>
          </div>
          <div className='input-group '>
            <input
              placeholder='Contraseña'
              type={textType}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <span
              className='btn btn-primary'
              onClick={(el) => {
                el.preventDefault()
                if (textType === 'password') {
                  setTextType('text')
                }

                if (textType === 'text') {
                  setTextType('password')
                }
              }}
            >
              <i className={`bi bi-eye-${textType === 'password' ? 'slash' : 'fill'} fs-2`}></i>
            </span>
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>
                  {__(formik.errors.password, 'El password debe contener Minimo 8 caracteres')}
                </span>
              </div>
            </div>
          )}
        </div>

        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-success w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>{__('singInButton', 'Ingresar')}</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {__('singInButtonValidate', 'Validando')}...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          <Link
            to='/auth/restorePassword'
            className='link-primary fs-8 fw-bolder'
            style={{marginLeft: '5px'}}
          >
            {__('singInForgotPasswordTitle', 'Olvidó su contraseña ?')}
          </Link>
        </div>
      </form>
    </div>
  )
}

export default LoginForm
