import {FC, useEffect} from 'react'
import {KTSVG} from '_metronic/helpers'
import useTranslate from 'app/hooks/useTranslate'
import {Obligation} from 'app/modules/obligations/components/list/core/_models'
import {MenuComponent} from '_metronic/assets/ts/components'

type Props = {
  obligationClaim: Obligation
  data: any
  toggleModal: any
}

/**
 * Actions Column (Detail, Edit, [Active, Inactive])
 * @param user
 * @constructor
 */
const ActionsCell: FC<Props> = ({obligationClaim, toggleModal}) => {
  const __ = useTranslate()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        {__('actionsTitle', 'Acciones')}
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-225px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => toggleModal(true, obligationClaim)}>
            {__('editTitle', 'Editar Registro')}
          </a>
        </div>
      </div>
    </>
  )
}

export {ActionsCell}
