import React, {useContext} from 'react'

import {PermissionContext} from 'app/providers/PermissionProvider'
import _ from 'lodash'

const useCheckRoleOrPermission = () => {
  const {permissions, role} = useContext(PermissionContext) || {}

  return React.useCallback((roleOrPermission: string) => {
    let [userRole, ...userPermissions] = roleOrPermission.split(':')
    let isAllowRole = false
    let isAllow = false

    _.forEach(role, (val) => {
      if (val.name === userRole) {
        isAllowRole = true
        return false
      }
    })

    if (isAllowRole) {
      return true
    }

    userPermissions = userPermissions?.join(':')?.split('|') || []

    _.forEach(userPermissions, (val: string, key: number) => {
      if (_.includes(permissions, val || 'notPermission')) {
        isAllow = true
        return false
      }
    })

    return isAllow
  }, [])
}

export default useCheckRoleOrPermission
