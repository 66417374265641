import {SHORT_WEEK_DAYS, WEEK_DAYS, MONTHS, SHORT_MONTHS} from 'app/config/constants'
import useTranslate from './useTranslate'
import _ from 'lodash'

const useFormatDateTime = () => {
  const __ = useTranslate()

  return (
    date: string | any,
    shortFormat: boolean = true,
    withHours: boolean = false,
    period: boolean = false,
    vector: boolean = false
  ) => {
    if (null === date) return __('notConnected', 'Nunca Conectado')

    let complement = _.some(['T', ' '], (char) => _.includes(date, char)) ? '' : ' 00:00:00'
    let datetime = new Date(date + complement)
    let format = null

    if (vector) {
      let year = datetime.getUTCFullYear().toString()
      format = SHORT_MONTHS[datetime.getMonth()] + ' ' + year

      return format
    }

    if (period) {
      format = MONTHS[datetime.getMonth()] + ' de ' + datetime.getUTCFullYear()

      return format
    }

    if (shortFormat) {
      format =
        SHORT_WEEK_DAYS[datetime.getDay()] +
        ', ' +
        datetime.getDate() +
        ' de ' +
        SHORT_MONTHS[datetime.getMonth()] +
        ' de ' +
        datetime.getUTCFullYear()

      if (withHours) {
        format +=
          ' hora: ' +
          datetime.getHours() +
          ':' +
          datetime.getMinutes() +
          ':' +
          datetime.getSeconds()
      }

      return format
    }

    if (!shortFormat) {
      format =
        WEEK_DAYS[datetime.getDay()] +
        ', ' +
        datetime.getDate() +
        ' de ' +
        MONTHS[datetime.getMonth()] +
        ' de ' +
        datetime.getUTCFullYear()
    }

    if (withHours) {
      format +=
        ' hora: ' + datetime.getHours() + ':' + datetime.getMinutes() + ':' + datetime.getSeconds()
    }

    return format
  }
}

export default useFormatDateTime
