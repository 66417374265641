import {useEffect, useState} from 'react'
import {useSession} from '@alejandro.devop/redux-persistance'
import {toAbsoluteUrl} from '_metronic/helpers'
import useTranslate from 'app/hooks/useTranslate'
import {useNavigate} from 'react-router-dom'
import useFormatDateTime from 'app/hooks/useFormatDateTime'
import {usePost} from '@alejandro.devop/react-json-api-client'
import Swal from 'sweetalert2'

const API_URL: any = process.env.REACT_APP_API_URL
const API_VERSION: any = process.env.REACT_APP_API_VERSION

const Certification = () => {
  const __ = useTranslate()
  const {store, setAll, clear, setKey} = useSession()
  const navigate = useNavigate()
  const formatDate = useFormatDateTime()

  const [sendRequestMainBranchCertification, loading] = usePost(
    'customers.mainBranchCertificationcertificate'
  )

  const handlerCertification = async () => {
    const {data: createResponse} = await sendRequestMainBranchCertification({
      data: {
        type: 'mainBranchCertifications',
        attributes: {
          main_branch_id: store.user.mainBranch.id,
          certification_type_id: '3',
        },
      },
    })

    if (createResponse.errors) {
      Swal.fire({
        text: 'Ocurrió un error',
        position: 'top-end',
        showConfirmButton: false,
        icon: 'error',
        timer: 1000,
      }).then(() => {
        navigate('/auth/login')
      })
    } else {
      Swal.fire({
        text: 'Certificación Guardada',
        position: 'top-end',
        showConfirmButton: false,
        icon: 'success',
        timer: 1000,
      }).then(() => {
        if (createResponse.attributes.certified) {
          try {
            let popup = window.open(
              API_URL +
                API_VERSION +
                `/obligation/obligationControls/attach/download/${createResponse.attributes.certified}`,
              '',
              'height=600,width=400'
            )
          } catch (e) {
            console.log('ERROR', e)
          }
        }

        if (store.isSafeComputer) {
          setAll({
            isChangePassword: false,
            isSafeComputer: store.isSafeComputer,
            isLogued: true,
            user: {
              user_id: store.user.user_id,
              user_email: store.user.user_email,
              permissions: store.user.permissions,
              rol: store.user.rol,
              main_branch_id: store.user.main_branch_id,
              secondary_branch_id: store.user.secondary_branch_id,
            },
          })

          navigate('/general/dashboard')
        } else {
          setAll({
            isTowFactor: true,
            user: {
              user_id: store.user.user_id,
              isRestorePassword: false,
              user_email: store.user.user_email,
            },
          })

          navigate('/auth/twoStep')
        }
      })
    }
  }

  const onLogout = () => {
    clear({})
    navigate('/auth/login')
  }

  useEffect(() => {
    if (!store?.isChangePassword && !store.token) {
      setAll({})
      navigate('/auth')
    }
  }, [])

  return (
    <div className='w-lg-800px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
      <div className='card card-flush w-300 py-5 flex-center '>
        <div className='card-body py-5 py-lg-5 mb-5'>
          <div className='row'>
            <div className='col-md-12'>
              <h2 className='fw-bolder text-gray-900 mb-5 text-center'>
                {__(
                  'certificationWelcome',
                  'CERTIFICACIÓN SEMESTRAL DE AUTORIZACIÓN PARA REPORTE Y/O CONSULTAS A PROCRÉDITO'
                )}
              </h2>
            </div>
          </div>

          <div className='row mt-10'>
            <div className='col-md-12'>
              <h5 className='text-gray-800'>{__('snrsTitle', 'Señores')}</h5>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <h6 className='text-gray-800'>{__('principalBranchTitle', 'FENALCO ANTIOQUIA')}</h6>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <h6 className='text-gray-800'>
                {__('certificationOperatorTitle', 'Operador de la base de datos PROCRÉDITO.')}
              </h6>
            </div>
          </div>

          <div className='fw-semibold fs-6 text-gray-500 mt-20'>
            <div className='alert alert-primary'>
              <div className='d-flex flex-column'>
                <p
                /* @ts-ignore */
                // style={{textAlign: 'justify', textJustify: 'inter-word'}}
                >
                  (
                  {__(
                    'certificationIntroTitle',
                    'Identifique de manera precisa y completa el nombre de la fuente de información o establecimiento de comercio'
                  )}
                  )
                </p>
              </div>
            </div>
          </div>

          <div className='row mt-15 mb-15'>
            <div className='col-md-12'>
              <h6
                className='text-gray-800' /* @ts-ignore */
                style={{textAlign: 'justify', textJustify: 'inter-word'}}
              >
                {`${store?.user?.mainBranch?.commercial_name} Con NIT o Rut ${store?.user?.mainBranch?.id_number}, en calidad de Fuente de información, certifico que la información Comercial, Crediticia, Financiera y de Servicios suministrada a FENALCO ANTIOQUIA como Operador de la Base de Datos PROCRÉDITO, cuenta con la debida autorización escrita y expresa otorgada por cada uno de los Titulares de la información reportados y/o consultados a la Base de Datos, en cumplimiento del numeral 6 del artículo 8 de la Ley 1266 de 2008.`}
              </h6>
            </div>
          </div>

          <div className='row mt-10 mb-20'>
            <div className='col-md-12'>
              <h5 className='text-gray-800'>{formatDate(new Date(), false)}</h5>
            </div>
          </div>

          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-success w-100 mb-5'
              disabled={loading}
              onClick={handlerCertification}
            >
              {!loading && (
                <span className='indicator-label'>
                  {__('certificationButton', 'Validar Certificación')}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {__('singInButtonValidate', 'Validando')}...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              type='button'
              onClick={onLogout}
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-success w-100 mb-5'
            >
              {__('cancelButton', 'Cancelar')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Certification
